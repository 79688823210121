import { Col, Row } from "antd"
import { NewsCard } from "../contents/NewsCard"
import SectionDivider from "../SectionDivider"

const gridInfo: any = {
    xxl: 6,
    lg: 6,
    md: 8,
    sm: 12,
    xs: 24
  }

const cardData = [
    {
        publisher: {
            name: 'Silicon Republic',
            country: 'IE',
            website: 'https://www.siliconrepublic.com',
            categories: [{ name: "Mainstream"}]
        },
        published_date: '2023-07-03',        
        image_url: 'https://www.siliconrepublic.com/wp-content/themes/silicon/img/logoblack.png',
        title: 'AI can spread misinformation better than humans, study claims',
        topics: {},
        industry_sectors: {},
        summary: `A new study has found that AI models like GPT-3 can be more effective at spreading misinformation than humans. The study found that people were more likely to believe false information that was generated by AI than by a human, even when the false information was clearly labeled as such. The study's findings suggest that AI models could be used to spread misinformation more effectively than humans, and that this is a potential threat to the integrity of online information. Google and CeADAR have taken steps to address this threat by developing tools to help users spot misleading or AI-generated images.`,
        external_url: 'https://www.siliconrepublic.com/machines/ai-misinformation-gpt-3-humans'
    },      
    {
        publisher: {
            name: 'Irish Tech News',
            country: 'IE',
            website: 'https://irishtechnews.ie',
            categories: [{ name: "Mainstream"}]
        },
        published_date: '2023-07-03',        
        image_url: 'https://irishtechnews-ie.exactdn.com/wp-content/uploads/2015/07/ITN-Logo_CMYK3001.png?lossy=1&ssl=1',
        title: 'CeADAR supercharges AI NewsHub amid growing demand for trustworthy news about artificial intelligence.',
        topics: {},
        industry_sectors: {},
        summary: 'CeADAR AI NewsHub is a free digital news platform that provides reliable information on global trends and developments in artificial intelligence and machine learning. The platform has been upgraded to include a personalized news feed, AI-powered enrichment, and dedicated channels for the United States and the United Kingdom. The platform is committed to providing high-quality information from reputable sources, and all stories link back to the original publishing source. A premium version of the platform is also available for industry members of the CeADAR Centre.',
        external_url: 'https://irishtechnews.ie/ceadar-supercharges-ai-newshub-amid-growing-demand-for-trustworthy-news-about-artificial-intelligence'
    },
    {
        publisher: {
            name: 'Tech Central',
            country: 'IE',
            website: 'https://techcentral.ie',
            categories: [{ name: "Mainstream"}]
        },
        published_date: '2023-07-03',        
        image_url: 'https://www.techcentral.ie/wp-content/themes/gonzo/images/logo.png',
        title: 'CeADAR AI news portal adds personalised content by industry sectors and media type',
        topics: {},
        industry_sectors: {},
        summary: `CeADAR, Ireland's National Centre for Applied AI, has upgraded its AI NewsHub to meet the growing demand for accurate information on global AI developments. The platform, developed by CeADAR's team of data scientists, now offers a personalized news feed, drawing from reputable sources, and has seen widespread engagement in 34 countries, particularly in the US and the UK. With an aim to be a showcase for Ireland's AI advancements, the upgraded AI NewsHub serves as a smart and easy-to-use tool, providing tailored content to various industry verticals, while also offering a premium version for CeADAR Centre industry members with advanced features.`,
        external_url: 'https://www.techcentral.ie/ceadar-ai-news-portal-adds-personalised-content-by-industry-sectors-and-media-type'
    },
    {
        publisher: {
            name: 'Business Plus',
            country: 'IE',
            website: 'https://businessplus.ie',
            categories: [{ name: "Mainstream"}]
        },
        published_date: '2022-10-15',        
        image_url: 'https://businessplus-ie.go-vip.net/wp-content/uploads/2022/01/BUSINESS_PLUS_LOGO.png',
        title: 'CeADAR Ireland Launches AI NewsHub',
        topics: {},
        industry_sectors: {},
        summary: `CeADAR Ireland has launched AI NewsHub, a free digital news platform aiming to provide reliable information on global developments in AI and machine learning. The platform uses AI to source information on these topics, employing natural language processing to automatically summarize each article, saving readers time compared to manual searches. The platform maintains quality control by sourcing information from reputable outlets and acknowledging the original sources. The team behind AI NewsHub focused on delivering an elegant architecture, functionality, and layout, ensuring accessibility, credibility, and responsiveness on multiple devices. The platform has received positive feedback, with users finding it a valuable tool to stay up-to-date with AI developments globally and nationally.`,
        external_url: 'https://businessplus.ie/tech/ceadar-ireland-ai-newshub'
    }    
]
const AsSeenIn: React.FC = () => {
    return (
            <Row justify="center" align="middle">
                <Col span={2}/>
                <Col span={20}>
                    <SectionDivider orientation={'left'} 
                            title= {`AI NewsHub's Spotlight in the Media`}
                            fontSize={null}
                            className={"long-title-divider"}/>

                    <Row gutter={[16,16]} justify="center" align="middle">
                        {
                            cardData.map((card: any, index: any) => (
                                <Col key={index} xs={gridInfo.xs} sm={gridInfo.sm} md={gridInfo.md} lg={gridInfo.lg} xxl={gridInfo.xxl}>
                                    <NewsCard card={card} widthScale={1}/>
                                </Col>
                        ))}
                    </Row>
                </Col>
                <Col span={2}/>
            </Row>
    )
}

export default AsSeenIn;