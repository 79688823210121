import React, { useEffect, useState } from "react";
import { CheckCircleTwoTone, ExclamationCircleTwoTone } from '@ant-design/icons';
import { Row, 
         Col, 
         Button, 
         Card, 
         Select, 
         Input, 
         Tag, 
         Form, 
         message, 
         Modal,
         Checkbox} from "antd";

import { useAutheDataContext } from '../../../contexts/AuthDataContext';
import api from "../../../api/api"
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs'
import {DATE_FORMAT, mapProfile, mapUser} from '../../../utils/utils'
import '../../../css/Common.css'
import '../../../css/ProfilePage.css'
import { UserProfileType } from "../../../types";


const API_URL : string = (process.env.REACT_APP_API_URL as string)
const {Option} = Select;

interface iProfileProps  {
  newProfile: boolean
}

const MIN_PREF = 2

const genderOptions = [
  {
    "id": 1,
    "name": "Male"
  },
  {
    "id": 2,
    "name": "Female"
  },
  {
    "id": 3,
    "name": "Other"
  },
  {
    "id": 4,
    "name": "Prefer not to disclose"
  }
]

const freqs = [
  {
    "id": 1,
    "name": "Daily"
  },
  {
    "id": 2,
    "name": "Weekly"
  },
  {
    "id": 3,
    "name": "Monthly"
  },
  {
    "id": 4,
    "name": "Most Relevant"
  }
]


const UserProfile: React.FC<iProfileProps> = (props: iProfileProps) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e: any) => {
    setIsChecked(e.target.checked);
  };

  const [topics, setTopics] = useState([])
  const [selectedTopics, setSelectedTopics] = useState<number[]>([]);

  const [industries, setIndustries] = useState([])
  const [selectedIndustries, setSelectedIndustries] = useState<number[]>([]);

  const [selectedFreq, setSelectedFreq] = useState<number>(freqs[1].id);

  const [categories, setCategories] = useState([])
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);  

  const [companies, setCompanies] = useState([])
  const [selectedCompany, setSelectedCompany] = useState<number>()

  const {user, setUser, userProfile, setUserProfile, authToken} = useAutheDataContext()
  const [messageApi, contextHolder] = message.useMessage()
  
  const navigate = useNavigate();

  let headers = authToken ? {
    headers: {
      'Authorization': `Bearer ${authToken}`
    }   
  } : {}

  useEffect(() => {
    document.title = `AI NewsHub | Settings`

    const fetchOptions = async () => {
      try {
        let res = await api.get(`${API_URL}/companies/`, headers)
        setCompanies(res.data.results.sort((a: any, b: any) => a.name.localeCompare(b.name)))        

        if(userProfile?.frequency) {
          setSelectedFreq(userProfile?.frequency)
        } else {
          freqs.forEach((obj: any) => {
            if (obj.name === "Weekly") {
              setSelectedFreq(obj.id)
            }
          })
        }

        res = await api.get(`${API_URL}/configurations/topics/`)
        setTopics(res.data.results)
        if (userProfile?.topics) {
          setSelectedTopics(userProfile?.topics)
        }

        res = await api.get(`${API_URL}/configurations/industry_sectors/`)
        setIndustries(res.data.results)
        if (userProfile?.industries){
          setSelectedIndustries(userProfile?.industries)
        }

        res = await api.get(`${API_URL}/configurations/categories/`)
        setCategories(res.data.results)   
        if (userProfile?.categories) { 
          setSelectedCategories(userProfile?.categories)    
        }
      }
      catch (err) {
        console.log('fetching errors: ', err)
      }
    }
    
    if (authToken) {
      fetchOptions()
    } else {
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

   const handleSubmit = async (inputs: any) => {
    const submitForm = async (companyId: any) => {
      const profileObj = {
        user: user?.userId,
        first_name: inputs.firstName,
        last_name:inputs.lastName,
        // date_of_birth: inputs.dateOfBirth ? dayjs(inputs.dateOfBirth).format('YYYY-MM-DD') : null,
        gender: inputs.gender ? inputs.gender : null,
        role_job_title: inputs.jobTitle ? inputs.jobTitle : null,
        company: companyId, 
        frequency: selectedFreq,
        topic_of_interests: selectedTopics, 
        industry_sectors: selectedIndustries,
        categories: selectedCategories
      }
  
      try {
        let res: any = {}
        if (props.newProfile || userProfile === null) {
          res = await api.post(`${API_URL}/profiles/`, profileObj, {
            headers: {
              'Authorization': `Bearer ${authToken ? authToken : null}`
            }      
          })  
        } else {
          if (!isDifferent(userProfile, profileObj)) {
            Modal.error({
              title: `Please make some changes first!`
            });    
            return      
          }
          res = await api.put(`${API_URL}/profiles/${userProfile?.profileId}/`, profileObj, {
            headers: {
              'Authorization': `Bearer ${authToken ? authToken : null}`
            }      
          }) 
        }

        setUser(mapUser(res.data.user))
        setUserProfile(mapProfile(res.data))

        if (props.newProfile) {
          messageApi.open({
            type: 'success',
            content: "Profile is succesfully created",
            duration: 1
          }).then(() => {
            messageApi.open({
              type: 'loading',
              content: "Redirecting to personalisation page",
              duration: 1 
            }).then(() => {
              if (userProfile?.hasSubscription) {
                navigate(`/personal/premium`)
              } else {
                navigate(`/personal/basic`)
              }
            })
          })
        } else {
          messageApi.open({
            type: 'success',
            content: "Profile is updated succesfully",
            duration: 2
          }).then(() => {
            messageApi.open({
              type: 'loading',
              content: "Redirecting to personalisation page",
              duration: 1 
            }).then(() => {
              if (userProfile?.hasSubscription) {
                navigate(`/personal/premium`)
              } else {
                navigate(`/personal/basic`)
              }
            })
          });
        }
      } catch (err) {
        console.log(err)
        messageApi.open({
          type: 'error',
          content: "Unable to update profile",
        });
  
      }
    }
    
    if (selectedTopics.length < MIN_PREF) {
      Modal.error({
        title: `Please select at least ${MIN_PREF} topics`
      });    
      return
    }

    if (selectedIndustries.length < MIN_PREF) {
      Modal.error({
        title: `Please select at least ${MIN_PREF} industry sectors`
      });    
      return
    }


    if (selectedCategories.length < MIN_PREF) {
      Modal.error({
        title: `Please select at least ${MIN_PREF} publisher categories`
      });    
      return
    }
    
    if (inputs.newCompany && isChecked) {
      try {
        let res: any
        res = await api.get(`${API_URL}/companies/`, headers)
        const existedCompany = res.data.results.filter((obj: any) => obj.name.toLowerCase() === inputs.newCompany.toLowerCase())
        if (existedCompany.length > 0) {
          Modal.error({
            title: `Company is already existed. Please select from the list`
          });    
          return    
        }
        res = await api.post(`${API_URL}/companies/`, {name: inputs.newCompany}, {
          headers: {
            'Authorization': `Bearer ${authToken ? authToken : null}`
          }      
        })  
        submitForm(res.data.id)
      } 
      catch (err) {
        console.log(err)
        messageApi.open({
          type: 'error',
          content: "Unable to create new company. Request aborted",
        });
        return
      }       
    } else {
      submitForm(inputs.company)
    }
  }

  const handleTopicClick = (topic: any) => {
    if (selectedTopics.includes(topic)) {
      setSelectedTopics(selectedTopics.filter((t) => t !== topic));
    } else {
      setSelectedTopics([...selectedTopics, topic]);
    }
  };

  const handleSectorClick = (industry: number) => {
    if (selectedIndustries.includes(industry)) {
      setSelectedIndustries(selectedIndustries.filter((t) => t !== industry));
    } else {
      setSelectedIndustries([...selectedIndustries, industry]);
    }
  };

  const handleCategoryClick = (category: number) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(selectedCategories.filter((t) => t !== category));
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  // const handleFredChange = (value: number) => {
  //   setSelectedFreq(value)    
  // }

  const handleCompanyChange = (value: number) => {
    setSelectedCompany(value)
  }

  const isArrDifferent = (arr1: any, arr2: any) => {
    if (arr1.length !== arr2.length) return true
    arr1 = arr1.sort()
    arr2 = arr2.sort()
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return true
    }
    return false
  }

  const isDifferent = (userProfile: UserProfileType, profileObj: {
    user: number | undefined; 
    first_name: any; 
    last_name: any;
    gender: any; 
    role_job_title: any; 
    company: any; 
    frequency: number; 
    topic_of_interests: number[]; 
    industry_sectors: number[]; 
    categories: number[];
  }) => {
    if (userProfile.firstName !==  profileObj.first_name) return true;
    if (userProfile.lastName !== profileObj.last_name) return true;
    if (userProfile.gender !== profileObj.gender) return true;
    if (userProfile.jobTitle !== profileObj.role_job_title) return true;
    if (userProfile.company !== profileObj.company) return true;
    if (isArrDifferent(userProfile.topics, profileObj.topic_of_interests)) return true;
    if (isArrDifferent(userProfile.industries, profileObj.industry_sectors)) return true;
    if (isArrDifferent(userProfile.categories, profileObj.categories)) return true;
    return false;
  }
  
  return (
    <div className="profile-container">
      <Form className="profile-form"
            initialValues = {{
              email:user?.email,
              firstName:userProfile?.firstName,
              lastName:userProfile?.lastName,
              company:userProfile?.company,
              jobTitle:userProfile?.jobTitle,
              dateOfBirth: userProfile?.dateOfBirth? dayjs(userProfile?.dateOfBirth, DATE_FORMAT): null,
              gender:userProfile?.gender,
              frequency:userProfile?.frequency         
            }}
            onFinish={handleSubmit}>
            <Card title="Your Profile" headStyle={{textAlign: "center", fontSize: "30px"}}>
              <Row gutter={[16, 16]}>
                <Col flex={1}>
                  <Form.Item>
                    <i>
                      <label>The red mark </label>
                      <label style={{ color: 'red'}}>*</label> 
                      <label> indicates mandatory fields</label>
                    </i>                  
                  </Form.Item>                                  
                </Col>            
              </Row>

              <Row gutter={[16, 16]}>
                  <Col xs={8} sm = {8} md={8} lg={4}>
                    <Form.Item>
                      <label>User Type</label>
                    </Form.Item>
                  </Col>            
                  <Col xs={16} sm = {16} md={16} lg= {20}>
                    <Form.Item>
                      <span>{userProfile?.hasSubscription? "Member": "Basic"}</span>
                    </Form.Item>
                  </Col>
              </Row>

              <Row gutter={[16, 16]}>
                  <Col xs={24} sm = {24} md={6} lg={4}>
                      <label>Email</label>
                  </Col>            
                  <Col xs={24} sm = {24} md={18} lg= {20}>
                    <Form.Item name="email">                  
                      <Input 
                      required
                      disabled/>
                    </Form.Item>                         
                  </Col>
              </Row>

              <Row gutter={[16, 16]}>
                  <Col xs={24} sm = {24} md={6} lg={4}>
                    <span style={{ color: 'red' }}>*</span><label> First Name</label>
                  </Col>            
                  <Col xs={24} sm = {24} md={18} lg= {20}>
                    <Form.Item  name="firstName">                              
                      <Input required/>
                    </Form.Item>                      
                  </Col>
              </Row>

              <Row gutter={[16, 16]}>
                  <Col xs={24} sm = {24} md={6} lg={4}>
                    <span style={{ color: 'red' }}>*</span><label> Last Name</label>
                  </Col>            
                  <Col xs={24} sm = {24} md={18} lg= {20}>
                    <Form.Item name="lastName">                            
                      <Input required/>
                    </Form.Item>                              
                  </Col>
              </Row>

              <Row gutter={[16, 16]}>
                  <Col xs={24} sm = {24} md={8} lg={4}>
                    <span style={{ color: 'red' }}>*</span>
                    <span>Company/<wbr/>Organisation</span>
                  </Col>
                  <Col xs={24} sm = {24} md={16} lg= {8}>
                    <Form.Item name="company" required>                                                                                                                                  
                        <Select style={{ width: "100%"}}
                                optionFilterProp="label"
                                showSearch
                                value={selectedCompany}
                                onChange={handleCompanyChange}
                                disabled={isChecked}>
                                {
                                  companies.map((company: any) => 
                                  <Option key={company.id} value={company.id} label={company.name}>
                                    {company.name}
                                  </Option>                            
                                  )
                                }
                          </Select>
                    </Form.Item>                                                                               
                  </Col>
                  <Col xs={24} sm = {24} md={8} lg= {5}>
                    <Form.Item>                                         
                      <Checkbox checked={isChecked} onChange={handleCheckboxChange}>
                          Not listed? Add company
                      </Checkbox>
                    </Form.Item>                  
                  </Col>              
                  <Col xs={24} sm = {24} md={16} lg= {7}>
                    <Form.Item name="newCompany">                                         
                      <Input required disabled={!isChecked}/>
                    </Form.Item>                        
                  </Col>
              </Row>              
              <Row gutter={[16, 16]}>
                  <Col xs={24} sm = {24} md={6} lg={4}>
                      <label>
                          Job Title
                      </label>                
                  </Col>            
                  <Col xs={24} sm = {24} md={18} lg= {20}>
                    <Form.Item name="jobTitle" >                                         
                      <Input/>
                    </Form.Item>                        
                  </Col>
              </Row>     
              <Row gutter={[16, 16]}>
                  <Col xs={24} sm = {24} md={6} lg={4}>
                      <label>Gender</label>
                  </Col>
                  <Col xs={24} sm = {24} md={18} lg= {20}>
                    <Form.Item name="gender" required>                                                                                                                                  
                        <Select style={{ width: "100%"}}>
                          {
                            genderOptions.map((gender: any) => 
                            <Option key={gender.id} value={gender.id}>
                              {gender.name}
                            </Option>
                            )
                          }
                          </Select>
                    </Form.Item>                                                                               
                  </Col>
              </Row>
            <Form.Item>            
            <Col span={24}>
              <Card title = {
                <div style={{
                  whiteSpace: "break-spaces",
                  wordBreak: "break-word"                 
                }}>
                  <span style={{ color: 'red' }}>*</span> 
                  Topics Of Interests: {selectedTopics.length} selected,&nbsp;
                  {(MIN_PREF - selectedTopics.length > 0) ? MIN_PREF - selectedTopics.length : 0} more required&nbsp;
                  {selectedTopics.length < MIN_PREF ? 
                  <ExclamationCircleTwoTone twoToneColor="red"/> : 
                  <CheckCircleTwoTone twoToneColor="#52c41a"/>}
                </div>   
              }>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {topics.map((topic: any) => (
                  <Tag
                    key={topic.id}
                    onClick={() => handleTopicClick(topic.id)}
                    className="profile-tag"
                    style={{
                      backgroundColor: selectedTopics.includes(topic.id)? "#1890ff": "transparent",
                      color: selectedTopics.includes(topic.id) ? "#fff" : "inherit"
                    }}
                    >
                    {topic.name}                                             
                  </Tag>
                ))}
              </div>
            </Card> 
          </Col>
          </Form.Item>
          <Form.Item name="sectors">                                                                                               
            <Col span={24}>
                <Card title = {
                  <div style={{
                    whiteSpace: "break-spaces",
                    wordBreak: "break-word"                 
                  }}>
                  <span style={{ color: 'red' }}>*</span> 
                  Industry Sectors: {selectedIndustries.length} selected,&nbsp; 
                  {(MIN_PREF - selectedIndustries.length) > 0 ? MIN_PREF - selectedIndustries.length : 0 } more required&nbsp;
                  {selectedIndustries.length < MIN_PREF ? 
                  <ExclamationCircleTwoTone twoToneColor="red"/> : 
                  <CheckCircleTwoTone twoToneColor="#52c41a"/>}
                  </div>   
                } 
                >
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {industries.map((industry: any) => (
                        <Tag
                            key={industry.id}
                            onClick={() => handleSectorClick(industry.id)}
                            className="profile-tag"
                            style={{
                              backgroundColor: selectedIndustries.includes(industry.id)? "#1890ff": "transparent",
                              color: selectedIndustries.includes(industry.id) ? "#fff" : "inherit",
                            }}
                            >
                            {industry.name}
                        </Tag>
                        ))}
                    </div>
                </Card>
            </Col>
          </Form.Item>   
          <Form.Item name="publishers">                                                                                      
            <Col span={24}>
                <Card title = {
                  <div style={{
                    whiteSpace: "break-spaces",
                    wordBreak: "break-word"                 
                  }}>
                    <span style={{ color: 'red' }}>*</span> 
                    Publisher Categories: {selectedCategories.length} selected,&nbsp; 
                    {(MIN_PREF - selectedCategories.length) > 0 ? MIN_PREF - selectedCategories.length : 0 } more required&nbsp;
                    {selectedCategories.length < MIN_PREF ? 
                    <ExclamationCircleTwoTone twoToneColor="red"/> : 
                    <CheckCircleTwoTone twoToneColor="#52c41a"/>}
                  </div>   
                } 
                >
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {categories.map((publisher: any) => (
                        <Tag
                            key={publisher.id}
                            onClick={() => handleCategoryClick(publisher.id)}
                            className="profile-tag"
                            style={{
                              backgroundColor: selectedCategories.includes(publisher.id)? "#1890ff": "transparent",
                              color: selectedCategories.includes(publisher.id) ? "#fff" : "inherit",
                            }}
                            >
                            {publisher.name}
                        </Tag>
                        ))}
                    </div>
                </Card>
            </Col>
          </Form.Item>   
          {/* <Form.Item name="freq">                                                                                     
            <Col span={24}>
              <Card title = {
                <>
                  <span style={{ color: 'red' }}>*</span> Frequency
                </>   
              } 
              >
                <Row gutter={[16, 16]}  style={{ paddingBottom: '16px' }}>
                    <Col xs={24} sm = {24} md={18} lg= {20}>
                        <Select style={{ width: "100%" }} 
                                value={selectedFreq} 
                                onChange={handleFredChange}>
                          {freqs.map((fred: any) => (
                            <Option key={fred.id} value={fred.id}>
                              {fred.name}
                            </Option>
                          ))}
                        </Select>
                    </Col>
                </Row>
              </Card>
            </Col>
          </Form.Item> */}
          {contextHolder}   
          <Form.Item>                                                                                    
              <Button type="primary" 
                      htmlType="submit">{props.newProfile ? "Create Profile": "Update Profile"}</Button>
          </Form.Item>  
            </Card>
      </Form>
    </div>
  );
};

export default UserProfile;
