import { Col, Row } from 'antd';
import '../../css/Common.css';
import SectionDivider from '../SectionDivider';
import ReactPlayer from 'react-player'

const PROMO_VIDEO_LINK = "https://www.youtube.com/watch?v=fndDfDLzDzA&ab_channel=CeADARIreland"

const VideoExplainer: React.FC = () => {
  return (    
    <Row justify="center" align="middle">
        <Col span={2}/>
        <Col span={20} style={{textAlign:'center'}}>
            <SectionDivider orientation={'left'} 
                            title={`Your AI Journey, Our Shared Destination`} 
                            className={"long-title-divider"}/>
                  <div className='intro-video'>
                      <ReactPlayer url= {PROMO_VIDEO_LINK} 
                                  controls={true}
                                  config={{
                                    youtube: {
                                      playerVars: { rel: 0 }
                                    }
                                  }}                            
                                  width='80%'
                                  height='60vh'                                  
                                  />
                  </div>                            
        </Col>
        <Col span={2}/>
    </Row>
  );
}

export default VideoExplainer;
