import {createContext, useContext, useState, useEffect} from 'react'
import {UserType, UserProfileType, AuthDataProviderProps } from '../types'
import { verifyToken } from '../api/providers'


interface AuthDataContextType {
    user: UserType | null,
    userProfile: UserProfileType | null,
    setUser: (user: UserType | null) => void,
    setUserProfile: (userProfile: UserProfileType | null) => void
    authToken: string | null,
    setAuthToken: (token: string | null) => void
}

const AuthDataContext = createContext<AuthDataContextType>({
    user: null,
    userProfile: null,
    setUser: () => {}, 
    setUserProfile: () => {},
    authToken: null,
    setAuthToken: () => {}
})

export const useAutheDataContext = () => {
    return useContext(AuthDataContext)
}

export const AuthDataProvider: React.FC<AuthDataProviderProps> = ({children}) => {
    const [user, setUser] = useState<UserType | null>(() => {
        const storedUser = localStorage.getItem("user")
        return storedUser ? JSON.parse(storedUser) : null
    });

    const [userProfile, setUserProfile] = useState<UserProfileType | null>(() => {
        const storedUserProfile = localStorage.getItem("userProfile")
        return storedUserProfile ? JSON.parse(storedUserProfile) : null
    })
    
    const [authToken, setAuthToken] = useState<string | null>(() => {
        const storedToken = localStorage.getItem("authToken")
        return storedToken ? JSON.parse(storedToken) : null
    });

    useEffect(() => {
        const validate = async () => {
            if (authToken) {
                try {
                    const res = await verifyToken(authToken)            
                    if (res.status === 200) {
                        localStorage.setItem("authToken", JSON.stringify(authToken))
                        if (user) {
                            localStorage.setItem("user", JSON.stringify(user))
                        }
                        if (userProfile) {
                            localStorage.setItem("userProfile", JSON.stringify(userProfile))
                        }
                    } else {
                        localStorage.removeItem("authToken")
                        localStorage.removeItem("user")
                        localStorage.removeItem("userProfile")
                    }        
                } catch (err: any) {
                    console.log('verify token failed: ', err)
                }
            }
        }
        validate()
    })

    const value: AuthDataContextType = {
        user,
        setUser,        
        userProfile,
        setUserProfile,
        authToken,
        setAuthToken
    };

    return (
    <AuthDataContext.Provider value={value}>
        {children}
    </AuthDataContext.Provider>
    );
};
