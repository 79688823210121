import { useState } from "react";
import { Form, Input, Button, Modal, Alert } from "antd";
import {
  LockOutlined,
  MailOutlined
} from "@ant-design/icons";
import "../../css/SignupPage.css";
import api from "../../api/api"
import EmailNotificationModal from '../EmailNotificationModal';
import TermOfUseModal from "../main/TermOfUseModal";
import PrivacyPoliciesModal from "../main/PrivacyPolicyModal";

const API_URL : string = (process.env.REACT_APP_API_URL as string)

interface SignUpModalProps {
  open: boolean,
  onCancel: (e: any) => void,
  onOpenLogin: (e: any) => void
}

const SignUpPage: React.FC<SignUpModalProps> = ({open, onCancel, onOpenLogin}) => {
  const [loading, setLoading] = useState(false);
  const [isExistedEmail, setExistedEmail] = useState(false)
  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const [openTermsModal, setTermsModelOpen] = useState(false);
  const [openPrivacyModal, setOpenPrivacyModal] = useState(false)

  const handleEmailModalClose = () => {
    setEmailModalVisible(false);
  };

  const onSubmit = async (values: any) => {
    const storedCredentials = {
      email: values.email.toLowerCase(),
      password: values.password,
      confirmPassword: values.confirmPassword
    }

    try {
      setLoading(true)
      await api.post(`${API_URL}/users/`, storedCredentials)
      onCancel({})      
      setEmailModalVisible(true);
         
    } catch (err: any) {
      console.log('error: ', err)
      setLoading(false)
      if (err.response.data.email) {
        setExistedEmail(true)
      }
    } finally {
      setLoading(false)
    }
  };

  const handleInputChange = () => {
    setExistedEmail(false)
  }

  return (
    <>
      <TermOfUseModal isOpen={openTermsModal}
                      setIsOpen={setTermsModelOpen}/>  
      <PrivacyPoliciesModal isOpen = {openPrivacyModal} 
                            setIsOpen = {setOpenPrivacyModal}/>  

      <Modal
        open={open}
        onCancel={onCancel}
        footer={null}
      >      
        <Form name="signup" onFinish={onSubmit}>
          <h1 className="signup-page__title">Sign Up</h1>
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input
              prefix={<MailOutlined className="site-form-item-icon" />}
              placeholder="Your Work Email"
              style={{ paddingLeft: 10 }}
              required
              onChange={handleInputChange}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              style={{ paddingLeft: 10 }}
              required
              onChange={handleInputChange}
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            rules={[
              { required: true, message: "Please confirm your password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords that you entered do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Confirm Password"
              style={{ paddingLeft: 10 }}
              required
              onChange={handleInputChange}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="signup-page__submit-button"
            >
              Sign Up
            </Button>
            <div className="signup-page__login-link">
              <span style={{ }}>By signing up, you agree to our <a onClick={(e) => setTermsModelOpen(true)}>T&C</a> and <a onClick={(e) => setOpenPrivacyModal(true)}>Privacy Policy</a>
              </span>
              <br/>     
              <span style={{ }}>Already have an account?</span>
              <Button type="link" 
                      onClick={(e) => onOpenLogin(e)}>Login</Button>
            </div>
          </Form.Item>
            {isExistedEmail && <Alert message="Email already exists." type="error" />}
        </Form>
      </Modal>
      <EmailNotificationModal visible={emailModalVisible} onClose={handleEmailModalClose} />
    </>
  );
};

export default SignUpPage;
