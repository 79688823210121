import React from 'react';
import { Row, Col, Space, Button } from 'antd';
import {
  EmailShare,
  FacebookShare,
  LinkedinShare,
  WhatsappShare,
  TwitterShare
} from "react-share-kit";
import { handleCopyLink } from '../../utils/share'

const ShareRow = ({ shareUrl, shareText }:any) => {
  return (
    <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
      <Col flex={1} style={{ textAlign: "center" }}>
        <Space>
        {/*
          <LinkedinShare url={shareUrl} title={shareText} size={32} bgColor={'rgb(9, 88, 217)'} round={true} />
          <FacebookShare url={shareUrl} title={shareText} size={32} quote={shareText} bgColor={'rgb(9, 88, 217)'} round={true} />
        */}
          <TwitterShare url={shareUrl} title={shareText} size={32} bgColor={'rgb(9, 88, 217)'} round={true} />
          <WhatsappShare url={shareUrl} title={shareText} size={32} separator=":: " bgColor={'rgb(9, 88, 217)'} round={true} />
          <EmailShare url={shareUrl} subject={shareText} body={shareText} size={32} bgColor={'rgb(9, 88, 217)'} round={true} />
          <div style={{ minHeight: '32px', minWidth: '32px', borderRadius: '32px', backgroundColor: 'rgb(9, 88, 217)', marginBottom: '6px' }} title="Click to copy the link to the article">
            <Button
              style={{
                backgroundColor: 'transparent',
                border: 'medium',
                padding: '0px',
                color: 'white',
                cursor: 'pointer',
                outline: 'currentcolor',
              }}
              onClick={() => handleCopyLink(shareUrl)}
            >
              <i className="fa fa-link"></i>
            </Button>
          </div>
        </Space>
      </Col>
    </Row>
  );
};

export default ShareRow;
