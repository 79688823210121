import React, { useState } from 'react';
import { Card, Input, Button, Row, Col, Form, message } from 'antd';
import { useAutheDataContext } from '../../../contexts/AuthDataContext';
import api from "../../../api/api"
import { useNavigate } from "react-router-dom";
import {
  LockOutlined,
} from "@ant-design/icons";

const API_URL : string = (process.env.REACT_APP_API_URL as string)

const PasswordChangeForm: React.FC = () => {
  const [submitting, setSubmitting] = useState(false);
  const {userProfile, authToken} = useAutheDataContext()
  const [messageApi, contextHolder] = message.useMessage()
  const navigate = useNavigate();

  let headers = authToken ? {
    headers: {
      'Authorization': `Bearer ${authToken}`
    }   
  } : {}

  const onSubmit =  async (values: any) => {
    if (values.newPassword !== values.confirmPassword) {
      messageApi.open({
        type: 'error',
        content: 'New password and confirm password you entered do not match!',
        duration: 2
      });
      return;    
    }

    if (values.newPassword === values.oldPassword) {
      messageApi.open({
        type: 'error',
        content: 'New password must be different from the old password!',
        duration: 2
      });
      return;    
    }

    try {
      setSubmitting(true)
      const userInfo: any = await api.get(`${API_URL}/users/me`, headers)
      const reqObj = {
        "email": userInfo.data.email,
        "old_password": values.oldPassword,
        "new_password": values.newPassword
      }
      const res = await api.put(`${API_URL}/auth/change_password/`, reqObj, headers)
      messageApi.open({
        type: 'success',
        content: res.data.message,
        duration: 2
      }).then(() => {
        messageApi.open({
          type: 'loading',
          content: "Redirecting to personalisation page",
          duration: 1 
        }).then(() => {
          if (userProfile?.hasSubscription) {
            navigate(`/personal/premium`)
          } else {
            navigate(`/personal/basic`)
          }
        })
      });

    } catch (err: any) {
        setSubmitting(false)
        messageApi.open({
          type: 'error',
          content: err.response.data.message,
          duration: 2
        });
    } finally {
      setSubmitting(false)
    }
  };

  return (
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
      <Form onFinish={onSubmit} className="profile-form">      
        <Card headStyle={{textAlign: "center", fontSize: "30px"}}
              title={
              <div style={{
                whiteSpace: "break-spaces",
                wordBreak: "break-word"                 
              }}>
                <span style={{ color: 'red' }}>*</span> Password Management
              </div>}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm = {24} md={6} lg={4}>
                      <label>Old Password</label>
            </Col>        
            <Col xs={24} sm = {24} md={18} lg= {20}>
              <Form.Item name="oldPassword"
                rules={[{ required: true, message: "Please input your old password!" }]}            
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}              
                  type="password"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm = {24} md={6} lg={4}>
              <label>New Password</label>
            </Col>        
            <Col xs={24} sm = {24} md={18} lg= {20}>
              <Form.Item name="newPassword"
                rules={[{ required: true, message: "Please input your new password!" }]}            
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}              
                  type="password"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm = {24} md={6} lg={4}>
              <label>Confirm Password</label>
            </Col>        
            <Col xs={24} sm={24} md={18} lg={20}>
              <Form.Item name="confirmPassword"
                  rules={[
                    { required: true, message: "Please confirm your password!" }
                  ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}              
                  type="password"
                />
              </Form.Item>
            </Col>
          </Row>
          {contextHolder}
          <Form.Item>
            <Button type="primary" 
                    htmlType="submit" 
                    loading={submitting}>
              Change Password
            </Button>
          </Form.Item>
        </Card>        
      </Form>                  
    </div>
);    
};

export default PasswordChangeForm;
