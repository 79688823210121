import React, {createContext, useContext, useState} from 'react'

type Theme = 'black' | 'white'

interface ThemeContextType {
    theme: Theme, 
    toggleTheme: () => void
}

interface ChildrenProps {
    children: React.ReactNode
}


const ThemeContext = createContext<ThemeContextType | undefined>(undefined)


export const ThemeProvider:React.FC<ChildrenProps> = ({children}) => {
    const [theme, setTheme] = useState<Theme>('black')
    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'white' ? 'black' : 'white'))
    }

    return (
        <ThemeContext.Provider value={{theme, toggleTheme}}>
            {children}
        </ThemeContext.Provider>
    )
}

export const useTheme = () => {
    const context = useContext(ThemeContext)
    if (context === undefined) {
        throw new Error('useTheme must be used within a ThemeProvider')
    }
    return context
}