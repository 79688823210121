import { Divider } from "antd"
import { useTheme } from "../contexts/ThemeContext"
import { getOppThemeColor } from "../utils/utils"

interface DividerType {
    orientation: any,
    title: string,
    fontSize?: string | null
    className?: string | null
}
const SectionDivider:React.FC<DividerType> = ({orientation, title, fontSize, className}) => {
    const {theme} = useTheme()
    return (
        <Divider orientation={orientation}  
                 style={{ borderColor:  "#b6bab3" }}>
            <h3 className={`section-title ${className}`}  style={{color: getOppThemeColor(theme)}}>{title}</h3></Divider>            
    )
}
export default SectionDivider