import { Button, Modal } from "antd"

interface LegalInfoType {
    isOpen: boolean,
    setIsOpen: any
}

const TERMS_OF_USE = {
    title: "Terms of Use",
    message: "By creating an Account on AI NewsHub, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send in relation to the platform. However, you may opt out of receiving any, or all, of these communications from us by emailing at",
    email: "info@ainewshub.ie",
    link: "https://ceadar.ie/terms-conditions/"
}
  
const TermOfUseModal: React.FC<LegalInfoType> = ({isOpen, setIsOpen}) => {
    return (
        <>
            <Modal
                    title={<strong>{TERMS_OF_USE.title}</strong>}
                    closable={false}
                    centered
                    open={isOpen}
                    footer={[
                    <Button key="ok" 
                            type='primary'
                            onClick={(e) => setIsOpen(false)}>OK</Button>]}            
                >
                    <p>{TERMS_OF_USE.message} <a href = "mailto: info@ainewshub.ie">{TERMS_OF_USE.email}</a></p>
                    <p>For full {TERMS_OF_USE.title} please click <a href={TERMS_OF_USE.link} rel="noreferrer" target="_blank">here</a></p>
            </Modal>        
        </>
    )
}

export default TermOfUseModal