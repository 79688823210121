import React from "react";
import PasswordChange from "./PasswordChange";
import { Row, Col, Layout } from "antd";
import NewsHubHeader from "../../main/NewsHubHeader";
import FooterCompoment from "../../main/NewsHubFooter";
import UserProfile from './UserProfile';

const { Content } = Layout;

const UserProfileUpdate: React.FC = () => {
  return (
    <Layout style={{background: "#fff"}}>
      <NewsHubHeader/>      
      <Content style={{ padding: '30px' }}>
        <Row gutter={[16, 16]}>
          <Col span={24}>          
              <UserProfile 
                newProfile={false}
              />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <PasswordChange/>
          </Col>
        </Row>
      </Content>
      <FooterCompoment/>      
    </Layout>
  );
};

export default UserProfileUpdate;

