import { Button, Modal } from "antd"


interface PrivacyPoliciesState {
    isOpen: boolean, 
    setIsOpen: any
}

const PrivacyPoliciesModal:React.FC<PrivacyPoliciesState> = ({isOpen, setIsOpen}) => {
    return (
        <>
            <Modal
                    closable={false}
                    centered
                    open={isOpen}
                    footer={[
                    <Button key="ok" 
                            type='primary'
                            onClick={(e) => setIsOpen(false)}>OK</Button>]}>
                <div style={{ maxHeight: "50vh", overflowY: "auto" }}>
                    <h1 style={{textAlign: "center"}}>Types of Data Collected</h1>
                    <h2>Personal Data</h2>
                    <p>While signing up on AI NewsHub, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:</p>
                    <ul style={{ listStyleType: "disc", marginLeft: "1rem" }}>
                        <li>Email address</li>
                        <li>First name and last name</li>
                        <li>Organisation</li>
                        <li>Cookies and Usage Data</li>
                    </ul>
                    <p>We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications by emailing us at: <a href="mailto:info@ainewshub.ie">info@ainewshub.ie</a></p>

                    <h2>Your rights under data protection law</h2>
                    <p>Under data protection laws, you have rights regarding your personal information:</p>
                    <ul style={{ listStyleType: "disc", marginLeft: "1rem" }}>
                        <li>Access: Obtain a copy of your data we hold.</li>
                        <li>Correction: Amend incorrect or incomplete data.</li>
                        <li>Erasure: Erase your data in specific situations.</li>
                        <li>Restrict processing: Limit data processing in certain cases.</li>
                        <li>Data portability: Transfer your data to another entity.</li>
                        <li>Object: Oppose data processing for marketing or if our basis is the Legitimate Interests Reason, including profiling.</li>
                    </ul>
                    <p>You can also withdraw consent if that's our legal reason for data use. To exercise these rights, contact us as indicated earlier. To verify identity, we might need specific details. There's no fee unless requests are baseless or excessive, which we might decline.</p>

                    <h2>Use of Data</h2>
                        <p>AI NewsHub uses collected data to:</p>
                        <ul style={{ listStyleType: "disc", marginLeft: "1rem" }}>
                            <li>Maintain and improve our Service.</li>
                            <li>Inform you of Service changes.</li>
                            <li>Enable interactive Service features.</li>
                            <li>Offer customer support.</li>
                            <li>Analyze for Service enhancement.</li>
                            <li>Monitor Service usage.</li>
                            <li>Address technical issues.</li>
                            <li>Fulfill purposes you provide it for.</li>
                            <li>Manage contractual obligations, including billing.</li>
                            <li>Send notices related to your account/subscription.</li>
                            <li>Share news and offers on similar goods/services, unless you opt-out.</li>
                            <li>Use in other ways specified when provided.</li>
                            <li>Any other purpose with your agreement.</li>
                        </ul>
                    <h2>Retention of Data</h2>
                        <p>We keep Personal Data as long as needed per this policy. It's retained to meet legal duties, settle disputes, and uphold our agreements.</p>
                        <p>Usage Data is kept for analysis, usually for a shorter span, unless needed for security, Service improvement, or as legally required.</p>

                    <p>For full Privacy Policies please click <a href='https://ceadar.ie/privacy-statement' rel="noreferrer" target="_blank">here</a></p>
                </div>
            </Modal>        
        </>
    )
}

export default PrivacyPoliciesModal