import React from 'react'
import { useTheme } from '../contexts/ThemeContext'
import { FloatButton } from 'antd'
import { BulbOutlined, BulbFilled} from '@ant-design/icons';

const bulbMap = {
    'black': <BulbOutlined/>,
    'white': <BulbFilled/>
}

export const ThemeSwitch: React.FC = () => {
    const {theme, toggleTheme} = useTheme()
    return  (
        <FloatButton type='primary' icon={bulbMap[theme]} onClick={toggleTheme}></FloatButton>
    )
}