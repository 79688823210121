import { Pagination } from 'antd'
import React from 'react'
import { CARD_PER_PAGE } from './configs'

interface NewsPaginationType {
    subscribed: boolean,
    totalPages: number,
    currentPage: number,
    handlePaginationClick: (page: number) => void
}

const NewsPagination: React.FC<NewsPaginationType> = ({subscribed, totalPages, currentPage, handlePaginationClick}) => {
    return  <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '30px', paddingBottom: '10px' }}>
              <Pagination
                total={totalPages}
                pageSize={CARD_PER_PAGE}
                current={currentPage}
                onChange={handlePaginationClick}
                showSizeChanger={false}
                className='news-pagination'
              />
    </div>
}

export default NewsPagination