import {Dayjs} from 'dayjs'
import { API_URL, DATE_FORMAT } from '../../utils/utils';
import { ChartTypes } from './types';
import api from '../../api/api';

const extractPieData = (data: any, duration: any, dataType: string) => {
    let res = data.map((obj: any) => {
        return {
            id: obj[dataType].id,            
            name: obj[dataType].name,
            count: obj.count[duration]
        }
    })
    return res
}

const fetchDataByType = async (startDate: Dayjs, 
                        endDate:Dayjs,
                        authToken: any,
                        endpointId: any)  => {
    let headers = authToken ? {
        headers: {
            'Authorization': `Bearer ${authToken}`
        }   
    } : {}
                                    
    let queryStr = `${API_URL}/${endpointId}?published_date_start=` + 
                   `${startDate.format(DATE_FORMAT)}` +
                   `&&published_date_end=${endDate.format(DATE_FORMAT)}`;
    return api.get(queryStr, headers)

}
export const fetchPieData = async (
                             chartType: ChartTypes,
                             startDate: Dayjs, 
                             endDate:Dayjs,
                             durationId: string,
                             authToken: any) => {
    switch (chartType) {
        case ChartTypes.CATEGORIES:
            const allCategories = await fetchDataByType(startDate, 
                                            endDate, 
                                             authToken, 'stats/categories')
            return extractPieData(allCategories.data.category_counts, durationId, 'category')

        case ChartTypes.SECTORS:
            const allSectors  = await fetchDataByType(startDate, 
                                             endDate, 
                                             authToken,'/stats/sectors')
            return extractPieData(allSectors.data.sector_counts, durationId, 'sector')
        default:
            break
    }
}

export const fetchWordCloud = async (startDate: Dayjs, endDate:Dayjs, authToken: any) => {
    let headers = authToken ? {
        headers: {
            'Authorization': `Bearer ${authToken}`
        }   
    } : {}
                                    
    let queryStr = `${API_URL}/stats/words?top_n=200&published_date_start=${startDate.format(DATE_FORMAT)}&published_date_end=${endDate.format(DATE_FORMAT)}`;
    return api.get(queryStr, headers)
}