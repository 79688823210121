import React from 'react';
import { Button, Modal } from 'antd';
import { Typography } from 'antd';
import logo from '../images/newshub-logo-main.png';

const {Text} = Typography;

interface EmailNotificationModalProps {
  visible: boolean;
  onClose: () => void;
}

const EmailNotificationModal: React.FC<EmailNotificationModalProps> = ({ visible, onClose }) => {
  return (
    <Modal
      centered
      closable={false}      
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="close" 
                onClick={onClose}
                style={{fontWeight: 'bold'}}>
          Close
        </Button>
      ]}      
    >
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 24,
          }}
        >
          <a href="/">
          <img
            src={logo}
            alt="Logo"
            style={{ height: 100 }}
          />
          </a>
        </div>  
        <Text style={{fontSize: '28px'}}>
          Thank you for signing up. Please check your email for a verification link to complete your registration.
          If you don't receive the email within a few minutes, please check your spam folder.
        </Text>        
      </div>
    </Modal>
  );
};

export default EmailNotificationModal;
