import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { message, Layout, Row,Col, Result } from "antd";
import "../css/SignupPage.css";
import logo from '../images/newshub-logo-main.png';
import api from "../api/api"
import {  useNavigate } from "react-router-dom";

const API_URL : string = (process.env.REACT_APP_API_URL as string)


const EmailVerification: React.FC = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token')

  const [validToken, setValidToken] = useState(true)
  const navigate = useNavigate()
  const initialized = useRef(false)

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      const validate_token = async () => {
        try {
          await api.post(`${API_URL}/auth/email/verify/`, { 
            token: `${token}`
          })
          setValidToken(true)
          message.loading({
            type: 'loading',
            content: "Redirecting to home page",
            duration: 3
          }).then(() => {
            navigate('/')
          })  
        }
        catch (err: any) {
          setValidToken(false)
        }  
      }
      validate_token()      
    }
  }, [])
  
  return (
    <>
    { validToken ? (
      <Layout>     
          <Row justify="center" align="middle" className="signup-page">
            <Col span={8}>
              <div className="signup-page__form-container">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: 24,
                  }}
                >
                  <a href="/">
                  <img
                    src={logo}
                    alt="Logo"
                    style={{ height: 100 }}
                  />
                  </a>
                </div>                 
                <div>
                  <h2>Congratulations! Your email has been successfully verified!</h2>
                </div>                 
              </div>
            </Col>
          </Row>
      </Layout>
    ) : (
      <Result
      status="404"
      title={<span style={{fontSize: '40px', fontWeight: 'bold'}}>404 Error</span>}
      extra={<div style={{fontSize: '24px'}}>The requested page could not be found.</div>}
    />
  )
  }
  </>)
};

export default EmailVerification;
