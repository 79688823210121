import React, {useState} from 'react';
import { Row, Col, Image, Card } from 'antd';
import '../../css/Common.css'; 
import '../../css/KeyFeatures.css';
import LoginPage from '../users/Login';
import SignUpPage from '../users/Signup';
import ForgotPasswordForm from '../users/ForgotPassword';

import featureIcon1 from '../../images/feature-icons/icon1.png'
import featureIcon2 from '../../images/feature-icons/icon2.png'
import featureIcon3 from '../../images/feature-icons/icon3.png'
import featureIcon4 from '../../images/feature-icons/icon4.png'
import featureIcon5 from '../../images/feature-icons/icon5.png'
import featureIcon6 from '../../images/feature-icons/icon6.png'
import { removeTabIndex } from '../../utils/utils';
interface Feature {
  icon: string;
  title: string;
  description: string;
  width: string;
}

const { Meta } = Card;

const features: Feature[] = [
  {
    icon: featureIcon1,
    title: 'AI-powered news curation and enrichment',
    description:
      'Stay up-to-date with the latest AI and ML news from around the world. Our AI algorithms curate the most relevant and up-to-date information, saving you time and effort.',
    width: '150px'
    
  },
  {
    icon: featureIcon2,
    title: 'Natural Language Processing (NLP) generated summaries',
    description:
      'Get the gist of an article quickly with our NLP-generated summaries. Save time and decide which articles to read in full.',
    width: '170px'
  },
  {
    icon: featureIcon3,
    title: 'Quality-controlled publishers',
    description:
      'Trust the news you read on our platform. We employ tight quality-control processes to ensure that news comes only from credible and authentic media outlets.',
    width: '175px'
  },
  {
    icon: featureIcon4,
    title: 'Personalised news feed',
    description:
      "Make your AI news journey your own. Personalize your news feed by selecting the type of publisher categories, industry sectors, and AI and ML topics you're most interested in.",
    width: '150px'
  },
  {
    icon: featureIcon5,
    title: 'Advanced search and analytics functionalities',
    description:
      'Find the information you need quickly and easily with our advanced search functionalities. Filter through our vast database to find news on a specific topic or industry sector.',
    width: '150px'
  },
  {
    icon: featureIcon6,
    title: 'Premium content access',
    description:
      'Access our complete database of tens and thousands of news articles with our premium version. Enjoy paginated content and advanced search functionalities to find the information you need.',
    width: '130px'
  },
];

const KeyFeatures: React.FC = () => {
  const [openLogin, setOpenLogin] = useState(false)
  const handleOpenLogin = (e: any) => {
    setOpenLogin(true)
    setOpenSignUp(false)
    setOpenForgotPassword(false)
    removeTabIndex(e)
  }

  const handleCloseLogin = (e: any) => {
    setOpenLogin(false)
    removeTabIndex(e)  
  }

  const [openSignUp, setOpenSignUp] = useState(false)

  const handleOpenSignUp = (e: any) => {
    setOpenLogin(false)
    setOpenSignUp(true)
    removeTabIndex(e)
  }

  const handleCloseSignUp = (e: any) => {
    setOpenSignUp(false)
    removeTabIndex(e)    
  }

  const [openForgotPassword, setOpenForgotPassword] = useState(false)

  const handleOpenForgotPassword = (e: any) => {
    setOpenLogin(false)
    setOpenForgotPassword(true)
  }

  const handleCloseForgotPassword = (e: any) => {
    setOpenForgotPassword(false)
  }

  return (
      <div>
        <div>
        <Row justify="center" align="middle">
          <Col span={3}/>
          <Col span={18}>
            <Row gutter={[16, 16]} justify="center" align="middle">
                {features.map((feature: Feature, index: number) => (
                  <Col key={index} xs={24} sm={24} md={24} lg={8}>
                      <Card 
                        hoverable
                        className='data-card'
                        cover={
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Image src={feature.icon} alt="" width={feature.width} preview={false}/>
                          </div>
                        }
                    >
                      <Meta
                        title={
                          <div style={{ whiteSpace: "pre-wrap" }}>
                            <strong className="card-title">{feature.title}</strong>
                          </div>}

                        description={
                          <div>
                            <span className="card-description">{feature.description}</span>
                          </div>
                        }
                      />
                    </Card>
                  </Col>
                ))}
            </Row>
          </Col>
          <Col span={3}/>
        </Row>          
        </div>
        {
          <>
            <LoginPage open={openLogin} 
                       onCancel={(e: any) => handleCloseLogin(e)}
                       onOpenSignUp = {(e: any) => handleOpenSignUp(e)}
                       onOpenForgotPassword = {(e: any) => handleOpenForgotPassword(e)}/>

            <ForgotPasswordForm open={openForgotPassword} 
                                onOpenLogin = {(e:any) => handleOpenLogin(e)} 
                                onCancel={(e: any) => handleCloseForgotPassword(e)}/>

            <SignUpPage open={openSignUp} 
                        onOpenLogin = {(e:any) => handleOpenLogin(e)}          
                        onCancel={(e: any) => handleCloseSignUp(e)}/>
          </>
        }        
        </div>
      )
};

export default KeyFeatures;
