import { Layout } from 'antd';
import NewsHubFooter from './NewsHubFooter';
import NewsHubHeader from './NewsHubHeader';
import HomeCards from '../contents/HomeCards';
import Banner from './BannerArea';
import {GridInfo} from '../../types'
import {homeSectionMap} from '../../utils/utils'
import { useEffect, useRef } from 'react';
import VideoExplainer from './VideoExplainer';
import AsSeenIn from './AsSeenIn';
import { useTheme } from '../../contexts/ThemeContext';

const { Content } = Layout;

const homeGridInfo: GridInfo = {
  xxl: 6,
  lg: 6,
  md: 8,
  sm: 12,
  xs: 24
}

const HomePage: React.FC = () => {
  useEffect(() => {
    document.title = "AI NewsHub | Home";
  }, []);

  const elementRef = useRef<HTMLDivElement>(null)

  const scrollTo = () => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView({behavior: 'smooth'})
    }
  }
  const {theme} = useTheme()

  return (
      <Layout style={{background: theme}}>
        <div className='header-style'>
          <NewsHubHeader/>
        </div>          
        <Banner scrollTo={scrollTo}/>
        <Content>
          <div ref={elementRef}>
          {
            Object.entries(homeSectionMap).map(([sectionKey, sectionObj], index: any)=> (
              <div id={`ai-section-${index}`} key={`ai-section-${index}`}>
                <HomeCards
                sectionKey = {sectionKey}
                gridInfo={homeGridInfo}/>
              </div>                  
            ))                        
          }
            <VideoExplainer/> 
            <AsSeenIn/> 
          </div>
        </Content>
        <NewsHubFooter/>
    </Layout>
  );
}

export default HomePage;
