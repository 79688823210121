import { Card, Row, Tag, Tooltip } from "antd"
import { Typography } from "antd"
import {IMAGE_PLACE_HOLDER, calculateCardHeight, countryCodeMap, getOppThemeColor, getTimeDistanceFromNow } from '../../utils/utils';
import { useEffect, useState } from "react";
import { CardDetails } from "./CardDetails";
import TagTooltip from "./TagTooltip";
import { useTheme } from "../../contexts/ThemeContext";
const {Title} = Typography

interface NewsCardType {
    card: any,
    widthScale: number
}

export const NewsCard: React.FC<NewsCardType> = ({card, widthScale}) =>  {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {theme} = useTheme()

    const [cardHeight, setCardHeight] = useState(calculateCardHeight(() => {}, window.innerWidth * widthScale))
 
    const cardStyle = {
        height: `${cardHeight}rem`
    }
    
    const handleCardClick = () => {
        setIsModalOpen(true)
    }

    const handleMouseEnter = (e: any) => {
        e.currentTarget.style.boxShadow = `0 0 10px ${getOppThemeColor(theme)}`        
    }

    const handleMouseLeave = (e: any) => {
        e.currentTarget.style.boxShadow = 'none'
    }
    const handleImageError = (e: any) => {
        e.target.src = IMAGE_PLACE_HOLDER;
      }
      
      const getImageSrc = (card: any) => {
        if (card.publisher.always_use_default_image || card.image_url == null) {
          return card.publisher.default_image_url;
        } else {
          return card.image_url;
        }
      }
    
      const handleOk = () => {
        setIsModalOpen(false);
      };
  
      const handleCancel = () => {
        setIsModalOpen(false);
      };


      useEffect(() => {
          window.addEventListener('resize', () => calculateCardHeight(setCardHeight, window.innerWidth * widthScale));
          return () => {
              window.removeEventListener('resize', () => calculateCardHeight(setCardHeight, window.innerWidth * widthScale));
          };
      },[])

      return (
        <>
            <Card
                hoverable
                className='data-card'
                style={cardStyle}
                onClick={handleCardClick}
                onMouseEnter={(e) => handleMouseEnter(e)}   
                onMouseLeave = {(e) => handleMouseLeave(e)}         
                cover={
                    <img
                        alt={`card-image-${card.publisher.name}`}
                        src={getImageSrc(card)}
                        onError={(e) => handleImageError(e)}
                    />
                    }                       
                    >
                <Tooltip>
                    <Tag className='tag-news-card'>
                        <strong>{countryCodeMap[card.publisher.country]}</strong>
                    </Tag>                        
                </Tooltip>
                <Card.Meta
                    description={
                        <div>
                            <a><strong className="card-publisher">{card.publisher.name} </strong></a>
                            <span className="card-publish-date">{getTimeDistanceFromNow(card.published_date)}</span>
                        </div>
                    }/>
                <Title level={4} 
                    ellipsis={false} 
                    className="card-title">
                    <span className='title-span'>{card.title}</span>          
                </Title>
                <Row gutter={[4,4]}>
                    {card.topics.length > 0 && (<TagTooltip name={card.topics[0].name} 
                                                            desc="Topic" 
                                                            color="#03045e"/>)}
                    {card.industry_sectors.length > 0 && (<TagTooltip name={card.industry_sectors[0].name} 
                                                                            desc="Industry Sector"
                                                                            color="#0077b6"/>)}
                    {card.publisher.categories.length > 0 && (<TagTooltip name={card.publisher.categories[0].name} 
                                                                        desc="Publisher Category"  
                                                                        color="#00b4d8"/>)}
                </Row>
            </Card>
            <CardDetails onOpen={isModalOpen} 
                         onOk={handleOk} 
                         onCancel={handleCancel}
                         card={card}/>            
        </>
    )
}