import React from 'react';
import { Button, Modal } from 'antd';
import { Typography } from 'antd';
import logo from '../images/newshub-logo-main.png';

const {Text} = Typography;

interface EmailNotVerifiedModalProps {
  visible: boolean;
  onClose: () => void;
}

const EmailNotVerifiedModal: React.FC<EmailNotVerifiedModalProps> = ({ visible, onClose }) => {
  return (
    <Modal
      centered
      closable={false}      
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="close" 
                onClick={onClose}
                style={{fontWeight: 'bold'}}>
          Close
        </Button>,        
        // <Button key="reativate" onClick={onClose}>
        //   Resend activation email
        // </Button>
      ]}      
    >
      <div>
      <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 24,
          }}
        >
          <a href="/">
          <img
            src={logo}
            alt="Logo"
            style={{ height: 100 }}
          />
          </a>
        </div> 
        <Text style={{fontSize: '28px'}}>
          Your email has not been verified yet. 
          Please click on the verification link that we have sent to your email address. 
          If you can not find the link in your inbox, please check your spam folder.
        </Text>
      </div>
    </Modal>
  );
};

export default EmailNotVerifiedModal;
