import { Layout, Row, Table } from 'antd';

import NewsHubFooter from './NewsHubFooter';
import NewsHubHeader from './NewsHubHeader';
import '../../css/Common.css'
import KeyFeatures from './KeyFeatures';
import { useEffect } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { CheckCircleTwoTone, ExclamationCircleTwoTone } from '@ant-design/icons';
import banner2 from '../../images/banner2.jpg';
import { useTheme } from '../../contexts/ThemeContext';
import { getOppThemeColor } from '../../utils/utils';
import ReactPlayer from 'react-player'

const { Content } = Layout;

interface DataType {
  key: string;
  featureName: string;
  basic: string;
  premium: string;
}


const PROMO_VIDEO_LINK = "https://www.youtube.com/watch?v=r7-yFtUUciU"

interface TitleStypeType {
  textAlign: string,
  paddingBottom: string,
  paddingTop: string,
  color: string
}

const columns: ColumnsType<DataType> = [
  {
    title: <h1><b>Features</b></h1>,
    dataIndex: 'featureName',
    key: 'featureName',
    render: (text) => <div  style={{textAlign: "center"}}><h3><strong>{text}</strong></h3></div>,
    align: 'center'
  },  
  {
    title: <h1><b>Basic</b></h1>,
    dataIndex: 'basic',
    key: 'basic',
    render: (text: any) => <div style={{textAlign: "center"}}>{text === "Yes" ? <CheckCircleTwoTone twoToneColor="#52c41a"/> : <ExclamationCircleTwoTone twoToneColor="red"/>}</div>,
    align: 'center'
  },
  {
    title: <h1><b>Member<span style={{ color: 'red' }}>*</span> </b></h1>,
    dataIndex: 'premium',
    key: 'premium',
    render: () => <div  style={{textAlign: "center"}}><CheckCircleTwoTone twoToneColor="#52c41a"/> </div>,
    align: 'center'
  }  
];

const data: DataType[] = [
  {
    key: '1',
    featureName: "AI-powered news curation and enrichment",
    basic: 'Yes',
    premium: 'Yes'
  },
  {
    key: '2',
    featureName: "Natural Language Processing (NLP) generated summaries",
    basic: 'Yes',
    premium: 'Yes'
  },
  {
    key: '3',
    featureName: "Quality-controlled publishers",
    basic: 'Yes',
    premium: 'Yes'
  },
  {
    key: '4',
    featureName: "Personalised news feed",
    basic: 'Yes',
    premium: 'Yes'
  },
  {
    key: '5',
    featureName: "Advanced analytics functionalities",
    basic: 'No',
    premium: 'Yes'
  },
  {
    key: '6',
    featureName: "Premium content access",
    basic: 'No',
    premium: 'Yes'
  }];

const BANNER2_CONTENT = "Simplified Access to AI News: Enriched, Searchable and Actionable"

const AboutPage: React.FC = () => {
  const {theme} = useTheme()
  
  const aboutTitleStyle: TitleStypeType | any = {
    textAlign: 'center', 
    paddingTop: '1.2rem', 
    paddingBottom: '1.2rem', 
    color: getOppThemeColor(theme)
  }

  useEffect(() => {
    document.title = "AI NewsHub | About";
  }, []);

  return (
    <Layout style={{background: theme}}>
      <NewsHubHeader/>
      <Content style={{ paddingBottom: '20px' }}>
        <h1 className="section-title" style={aboutTitleStyle}>A smart and easy-to-use digital news platform for AI</h1>

        <Row justify='center' 
             align='middle'>
              <ReactPlayer url= {PROMO_VIDEO_LINK} 
                          controls={true}
                          config={{
                            youtube: {
                              playerVars: { rel: 0 }
                            }
                          }}                            
                          width='80%'
                          height='70vh'                                  
              />
        </Row>

        <div id="banner2" className="banner2" style={{backgroundColor: theme}}>
          <h1 className="section-title" style={aboutTitleStyle}>{BANNER2_CONTENT}</h1>
          <Row justify='center' align='middle'>
              <img src={banner2} alt='banner2-about'/> 
          </Row>
        </div>        
        <h1 className="section-title" style={aboutTitleStyle}>Delivering elegance in architecture, design, and layout via …</h1>
        <KeyFeatures/>
        <h1 className="section-title" style={aboutTitleStyle}>Offering</h1>        
        <Row justify='center' align='middle'>
            <Table columns={columns} 
                   dataSource={data} 
                   pagination={false}
                   bordered
                   style={{overflow: "auto"}}/>            
        </Row>
        <Row justify='center' align='middle' style={{paddingTop: '20px'}}>
          <h1 style={{color: getOppThemeColor(theme)}}><span style={{ color: 'red' }}>*</span>CeADAR member companies can avail of premium features for AI NewsHub by submitting an access request at <a href = "mailto: info@ainewshub.ie">info@ainewshub.ie</a></h1>
        </Row>

      </Content>
      <NewsHubFooter/>
    </Layout>
  );
}

export default AboutPage;
