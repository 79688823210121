import React, {useState} from 'react';
import {Menu, Button, Tag, Drawer} from 'antd';
import { useAutheDataContext } from '../../contexts/AuthDataContext';
import {
        HomeOutlined, 
        InfoCircleOutlined, 
        LoginOutlined,
        SettingOutlined,
        LogoutOutlined,
        UserAddOutlined,
        CloseOutlined} from '@ant-design/icons'
import '../../css/Header.css'; 
import '../../css/Common.css'; 

import { calculateMenuWidth } from '../../utils/utils';
import { UserModals } from './UserModals';

interface MobileMenuHeaderType {
  handleHomeClick: (e: any) => void,
  handleAboutClick: (e: any) => void,
  handleSettingsClick: (e: any) => void,
  handleLogout: (arg: boolean) => void,
  mobileHeaderClose: () => void,
  visible: boolean
}

export const MobileMenuHeader: React.FC<MobileMenuHeaderType> = ({
                                                handleHomeClick, 
                                                handleAboutClick,
                                                handleSettingsClick,
                                                handleLogout,
                                                mobileHeaderClose,
                                                visible
                                              }) => {
  const { authToken, userProfile } = useAutheDataContext()

  const [openLogin, setOpenLogin] = useState(false)
  const [openSignUp, setOpenSignUp] = useState(false)
  const [openForgotPassword, setOpenForgotPassword] = useState(false)

  return (
    <div>
    <Drawer
      title=""
      placement="right"
      onClose={mobileHeaderClose}
      open={visible}
      drawerStyle={{ backgroundColor: 'black'}}
      width={`"${calculateMenuWidth(window.innerWidth)}"`}
      closeIcon={<CloseOutlined style={{ color: "white" }} />}>

      <Menu mode="vertical" 
            theme="dark"
            style={{ backgroundColor: 'rgba(255, 255, 255, 0)'}}>       
            <Menu.Item key="home" 
                icon={<HomeOutlined style={{ fontSize: '22px' }}/>}
                onClick={handleHomeClick}
                style={{lineHeight: '12px'}}
                ><strong className="menu-text">Home</strong></Menu.Item>
            <Menu.Item key="about" 
                icon={<InfoCircleOutlined style={{ fontSize: '22px' }}/>}
                onClick={handleAboutClick}
                style={{lineHeight: '12px'}}
                ><strong className="menu-text">About</strong></Menu.Item>
            {authToken
              ? (
                <> 
                  <Menu.Item key="settings" 
                            style={{lineHeight: '12px'}}
                            icon={<SettingOutlined style={{ fontSize: '22px' }}/>}
                                            onClick={handleSettingsClick}><strong className="menu-text">Settings</strong></Menu.Item>
                  <Menu.Item icon={<LogoutOutlined style={{ fontSize: '22px' }}/>} 
                              style={{lineHeight: '12px'}}                              
                              key="logout" 
                              onClick={() => handleLogout(false)}><strong className="menu-text">Logout</strong></Menu.Item>
                </>): (
                  <>
                    <Menu.Item key="login" 
                              style={{lineHeight: '12px'}}                                
                              icon={<LoginOutlined style={{ fontSize: '22px' }}/>} 
                              onClick={(e) => setOpenLogin(true)}><strong className="menu-text">Log in</strong></Menu.Item>      

                    <div style={{ position: "relative" }}>
                      <Button type="primary" size='large' className='control-button' onClick={(e) => setOpenSignUp(true)}> <UserAddOutlined style={{ fontSize: '22px' }}/> <strong className="menu-text">Sign Up</strong></Button>            
                    </div>
                  </>
                )
            }    
      </Menu>    
      {authToken
          && (
                  <Tag  color="#f50" style={{position: "absolute", left: "21%", top: "260px"}}><strong>{(userProfile && userProfile.hasSubscription) ? "Member" : "Basic"}</strong></Tag>            
            )
      }       
    </Drawer>
    {<UserModals openLogin={openLogin} 
                     setOpenLogin={setOpenLogin}
                     openSignUp={openSignUp}
                     setOpenSignUp={setOpenSignUp}
                     openForgotPassword={openForgotPassword}
                     setOpenForgotPassword={setOpenForgotPassword}
                     />}
  </div>
  )
};
