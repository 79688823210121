import '../../css/Header.css'; 
import '../../css/Common.css'; 
import React from 'react';
import { Row, Col, Button, Tooltip} from 'antd';
import { UserOutlined} from '@ant-design/icons';
import { useAutheDataContext } from '../../contexts/AuthDataContext';
import {DotChartOutlined, 
        GlobalOutlined, 
        FileTextOutlined} from '@ant-design/icons'

interface ContentMenuType {
    handleShowPersonalised: () => void,
    handleShowNews: () => void,
    handleShowAnalytics: () => void,
    handleWhitePaper: () => void
}

export const ContentMenu: React.FC<ContentMenuType> = ({handleShowPersonalised, 
                                                        handleShowNews, 
                                                        handleShowAnalytics,
                                                        handleWhitePaper}) => {
      const {userProfile} = useAutheDataContext()
      
      return (
      <>
            <Row className="ai-menu-row"
                  justify="center" 
                  align="middle"
                  gutter={[8,8]}
                  >
                  <Col>
                        <Tooltip title={<strong><i>Personalised for you</i></strong>} key={'personal'}>
                              <Button id={`ai-menu-button-personal`}  
                                    type="primary" size="large" 
                                    onClick={handleShowPersonalised}
                                    className={`control-button`}> 
                                    <strong className="menu-text"><UserOutlined/> Your Feeds</strong>
                              </Button>
                        </Tooltip>
                  </Col>
                  <Col>
                        <Tooltip title={<strong><i>Everything in AI</i></strong>} key={'ainews'}>
                              <Button id={`ai-menu-button-ainews`}  
                                    type="primary" size="large" 
                                    onClick={handleShowNews}
                                    className={`control-button`}>
                                    <strong className="menu-text"><GlobalOutlined/> AI News</strong>
                              </Button>
                        </Tooltip>
                  </Col>
                  {userProfile && userProfile.hasSubscription && (
                  <Col>
                        <Tooltip title={<strong><i>Gather Actionable Insights</i></strong>} key={'analytics'}>
                              <Button id={`ai-menu-button-analytics`}  
                                    type="primary" size="large" 
                                    onClick={handleShowAnalytics}
                                    className={`control-button`}>
                                    <strong className="menu-text"><DotChartOutlined/> Analytics</strong>
                              </Button>
                        </Tooltip>
                  </Col>
                  )}        
                  <Col>
                        {
                               (
                                    <Tooltip title={<strong><i>Download whitepaper</i></strong>} key={'whitepaper'}>
                                          <Button id={`ai-whitepaper-btn`}  
                                                type="primary" size="large" 
                                                onClick={handleWhitePaper}
                                                className={`control-button`}>
                                                <strong className="menu-text"><FileTextOutlined/> AI Whitepaper</strong>
                                          </Button>
                                    </Tooltip>
                              )
                        }        
                  </Col>                     
            </Row>
      </>
)};

