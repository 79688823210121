import React, {useState} from 'react';
import {Button, Row, Col, Tag} from 'antd';
import { useAutheDataContext } from '../../contexts/AuthDataContext';
import {
        HomeOutlined, 
        InfoCircleOutlined, 
        LoginOutlined,
        SettingOutlined,
        LogoutOutlined,
        UserAddOutlined} from '@ant-design/icons'
import '../../css/Header.css'; 
import '../../css/Common.css'; 
import { UserModals } from './UserModals';

interface DesktopMenuHeaderType {
  handleHomeClick: (e: any) => void,
  handleAboutClick: (e: any) => void,
  handleSettingsClick: (e: any) => void,
  handleLogout: (arg: boolean) => void
}

export const DesktopMenuHeader: React.FC<DesktopMenuHeaderType> = ({
                                                handleHomeClick, 
                                                handleAboutClick,
                                                handleSettingsClick,
                                                handleLogout}) => {
  
  const { authToken, userProfile } = useAutheDataContext()
  
  const [openLogin, setOpenLogin] = useState(false)
  const [openSignUp, setOpenSignUp] = useState(false)
  const [openForgotPassword, setOpenForgotPassword] = useState(false)

  return (
    <>    
        <Row className="ai-menu-row"
                justify="center" 
                align="middle">            
            <Col key="home" onClick={handleHomeClick}>
                <strong className="ai-menu-button">
                    <HomeOutlined style={{ fontSize: '22px' }}/> Home
                </strong>
            </Col>
            <Col key="about" onClick={handleAboutClick}>
                <strong className="ai-menu-button">
                    <InfoCircleOutlined style={{ fontSize: '22px' }}/> About
                </strong>
            </Col>
            {authToken
                ? (
                <> 
                    <Col key="settings" onClick={handleSettingsClick}>
                        <strong className="ai-menu-button">
                            <SettingOutlined style={{ fontSize: '22px' }}/> Settings
                        </strong>
                    </Col>
                    <Col key="logout" onClick={() => handleLogout(false)}>
                        <strong className="ai-menu-button">
                            <LogoutOutlined style={{ fontSize: '22px' }}/> Logout
                        </strong>
                    </Col>
                </>): (
                    <>
                    <Col key="login" onClick={(e) => setOpenLogin(true)}>
                        <strong className="ai-menu-button"><LoginOutlined style={{ fontSize: '22px' }}/> Log in</strong>
                    </Col>      
                    
                    <Col key="signup" onClick={(e) => setOpenSignUp(true)} style={{paddingLeft: "10px"}}>
                        <Button type="primary" size='large' style={{color: "white", lineHeight: '20px'}}> 
                        <UserAddOutlined style={{ fontSize: '22px' }}/> <strong className="menu-text">Sign Up</strong></Button>            
                    </Col>
                    </>
                )
            }
            {authToken
                && (
                <> 
                    <Col key="userType">
                        <Tag  color="#f50" ><strong>{(userProfile && userProfile.hasSubscription) ? "Member" : "Basic"}</strong></Tag>            
                    </Col>
                </>
                )
            }
        </Row>   
        {<UserModals openLogin={openLogin} 
                     setOpenLogin={setOpenLogin}
                     openSignUp={openSignUp}
                     setOpenSignUp={setOpenSignUp}
                     openForgotPassword={openForgotPassword}
                     setOpenForgotPassword={setOpenForgotPassword}/>}
    </>
  )
};
