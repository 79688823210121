import '../../css/Common.css';
import { Col, Layout, Row, Space, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import CardSection from '../contents/CardSection';
import { CARD_PER_PAGE, gridInfo } from '../contents/configs';
import { API_URL, ChartType, DATE_FORMAT, JumpStep, durationMap, getFirstPart, getOppThemeColor, sortByPublishedDate } from '../../utils/utils';
import { useAutheDataContext } from '../../contexts/AuthDataContext';
import api from '../../api/api';
import { IndustryStats } from './sectorsStats/SectorsStats';
import { SearchCriteria } from '../../types';
import { CategoriesStats } from './categoriesStats/CategoriesStats';
import {Dayjs} from 'dayjs'
import WordCloudChart from './wordcloud/WordCloudChart';
import NewsPagination from '../contents/NewsPagination';
import NewsHubHeader from '../main/NewsHubHeader';
import NewsHubFooter from '../main/NewsHubFooter';
import SectionDivider from '../SectionDivider';
import { useTheme } from '../../contexts/ThemeContext';
import {CaretLeftOutlined, 
        CaretRightOutlined,
        CloudOutlined,
        AppstoreOutlined,
        PieChartOutlined
      } from '@ant-design/icons'
import { TimeBtnList } from './TimeBtnList';

const {Content} = Layout

const chartInfos: Record<ChartType, any>  = {
  [ChartType.WordCloud]: {
    icon: <CloudOutlined/>,
    title: ' AI News Keywords',
    desc: 'Navigate Articles by Trending Terms'
  },
  [ChartType.Publisher]: {
    icon: <AppstoreOutlined/>,
    title: ' Publisher Breakdown',
    desc: 'Find AI News by Publisher'
  },
  [ChartType.Industry]: {
    icon: <PieChartOutlined/>,
    title: ' Sector Breakdown',
    desc: 'Find AI News by Sector'
  }
}

const prevChartMap: Record<ChartType, ChartType> = {
  [ChartType.WordCloud]: ChartType.Industry,
  [ChartType.Industry]: ChartType.Publisher,
  [ChartType.Publisher]: ChartType.WordCloud
}

const nextChartMap: Record<ChartType, ChartType> = {
  [ChartType.WordCloud]: ChartType.Publisher,
  [ChartType.Publisher]: ChartType.Industry,
  [ChartType.Industry]: ChartType.WordCloud
}

const VisualisationPage: React.FC = () => {
  const [startDate, setStartDate] = useState<Dayjs>(durationMap.last_30_days.startDate)
  const [endDate, setEndDate] = useState<Dayjs>(durationMap.last_30_days.endDate)
  const [durationId, setDurationId] = useState<string>('last_30_days')
  const [durationText, setDurationText] = useState<string>(durationMap.last_30_days.text)
  const [textResult, setTextResult] = useState<string>('All AI News in ' + durationMap.last_30_days.text)
  const [activeButton, setActiveButton] = useState<number>(0)

  const {authToken} = useAutheDataContext()
  const [newsData, setNewsData] = useState(null)
  const [newsLoading, setNewsLoading] = useState<boolean>(false)
  const [searchOptions, setSearchOptions] = useState<any>(null)  
  const [totalPages, setTotalPages] = useState<number>(1)
  const [currentPage, setCurrentPage] = useState<number>(1)
  
  const {theme} = useTheme()

  const [displayChart, setdisplayChart] = useState<ChartType>(ChartType.WordCloud)
  const [nextChart, setNextChart] = useState<ChartType>(nextChartMap[ChartType.WordCloud])
  const [prevChart, setPrevChart] = useState<ChartType>(prevChartMap[ChartType.WordCloud])

  const navigate = useNavigate()

  // topK is used to display only first K results, used in word cloud
  const fetchData = async (queryStr: string, headers: any, topK: any = null) => {
    let res: any = null
    try {
      setNewsLoading(true)
      res = await api.get(queryStr, headers)
      let displayData = res.data.results
      displayData.map((x: any) => (x.contentSummary = getFirstPart(x.summary)))
      if (topK) {
        displayData = displayData.splice(0, topK)
      }
      displayData = sortByPublishedDate(displayData)
      console.log('displayData', displayData)
      setNewsData(displayData)  
    } catch (err) {
      console.log('err:', err)
    }
    finally {
      setNewsLoading(false)
    }
    return res
  }
  const handleSearch = async (options: SearchCriteria, searchPage: number = 1) => {
    try {
      setSearchOptions(options)
      let queryStr = `${API_URL}/contents/articles/search/?page=${searchPage}&page_size=${CARD_PER_PAGE}` +
        `&published_date_start=${refVisPage.current.startDate.format(DATE_FORMAT)}` + 
        `&&published_date_end=${refVisPage.current.endDate.format(DATE_FORMAT)}`;        
      let headers = authToken ? {
          headers: {
            'Authorization': `Bearer ${authToken}`
          }   
      } : {}
      if (options.keyword) {
        queryStr += `&&query=${options.keyword}`
        setTextResult(`Buzz & Topics: '${options.keyword}' in AI over ${refVisPage.current.durationText}`)
      }        
      if (options.category) {
        queryStr += `&&category=${options.category}`
        setTextResult(`In the Pages of '${options.displayText}': AI Coverage from the ${refVisPage.current.durationText}`)        
      }

      if (options.industry) {
        queryStr += `&&industry_sector=${options.industry}`
        setTextResult(`Spotlight on '${options.displayText}': AI Developments in the ${refVisPage.current.durationText}`)                
      }

      let res = await fetchData(queryStr, headers, options.topK ? options.topK : null)
      if (res && searchPage === 1) {
        setTotalPages(res.data.count)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const resetSearchPanel = (timeInfo: string) => {
    setTextResult('All AI News in ' + timeInfo)
    handleSearch({})
  }

  const handleTimeBtnClick = (btnIndex: number, durationId: string, obj: any) => {
    setActiveButton(btnIndex)    
    setStartDate(obj.startDate);
    setEndDate(obj.endDate)
    setDurationId(durationId)
    setDurationText(obj.text)
    resetSearchPanel(obj.text)
  }

  const handlePaginationClick = async (page: number) => {
    handleSearch(searchOptions, page)
    setCurrentPage(page)
  }

  const changeChart = (inc: number) => {
    let tmp = displayChart + inc
    if (tmp > ChartType.Industry) tmp = ChartType.WordCloud
    else if (tmp < ChartType.WordCloud) tmp = ChartType.Industry
    setdisplayChart(tmp)
    setNextChart(nextChartMap[tmp as ChartType])
    setPrevChart(prevChartMap[tmp as ChartType])
    resetSearchPanel(durationText)
  }

  const refVisPage = useRef({startDate, endDate, durationText})

  useEffect(() => {
    if (authToken) {
      document.title = "AI NewsHub | Analytics";
      refVisPage.current = {startDate, endDate, durationText}  
      handleSearch({})
    } else {
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);
  
  return (
    <Layout style={{backgroundColor: theme}}>
    <NewsHubHeader/>
    <Content>
    {authToken ? <div style={{backgroundColor: theme}}>
      
      <h1 className='section-title' 
          style={{ color: getOppThemeColor(theme), 
                   fontSize: '1.375rem',
                   textAlign: 'center',
                   paddingTop: '1rem'}}>
          {chartInfos[displayChart].icon} {chartInfos[displayChart].desc}
      </h1>

      <Row justify="center" align="middle" style={{marginTop: '1rem'}}>
          <Col span={2}/>
          <Col span={2}>
            <TimeBtnList durationMap={durationMap}
                         activeButton={activeButton}
                         handleTimeBtnClick={handleTimeBtnClick}
                         className='chart-desktop'/>
          </Col>
          <Col span={18}>
            {
              displayChart === ChartType.WordCloud ? (
                <WordCloudChart  handleSearch = {handleSearch}
                startDate={startDate}
                endDate={endDate}/>

              ) : (displayChart === ChartType.Publisher) ? (
                  <CategoriesStats handleSearch = {handleSearch}
                  durationId = {durationId}
                  startDate={startDate} 
                  endDate={endDate}/>
              ) : (
                <IndustryStats handleSearch = {handleSearch}
                durationId={durationId}
                startDate={startDate}
                endDate={endDate}/>
              )
            }
          </Col>
          <Col span={2}/>
      </Row>      
      <Row justify="center" align="middle">
          <Col span={4}/>
          <Col span={6}>
            <Space onClick={(e) => changeChart(JumpStep.PREV)}
                   className={'chart-mobile-btn'}>
              <span style={{color: getOppThemeColor(theme)}}><CaretLeftOutlined/></span>  
              <h1 className='section-title chart-desktop' style={{color: getOppThemeColor(theme), 
                                                    fontSize: '1.375rem',
                                                    marginTop: '0.8rem',
                                                    whiteSpace: 'nowrap'}}>{chartInfos[prevChart].title}</h1>
            </Space>            
          </Col>
          <Col span={6} className='chart-desktop'/>
          <Col span={6}>
            <Space onClick={(e) => changeChart(JumpStep.NEXT)}
                    className={'chart-mobile-btn'}>
              <h1 className='section-title chart-desktop' style={{color: getOppThemeColor(theme), 
                                                    fontSize: '1.375rem',
                                                    marginTop: '0.8rem',
                                                    whiteSpace: 'nowrap'}}>{chartInfos[nextChart].title}</h1>                    
              <span
                    style={{color: getOppThemeColor(theme)}} 
                    onClick={(e) => changeChart(+1)}><CaretRightOutlined/>
              </span>                
            </Space>
          </Col>
          <Col span={2}/>
      </Row>
      <TimeBtnList durationMap={durationMap}
                         activeButton={activeButton}
                         handleTimeBtnClick={handleTimeBtnClick}
                         className='chart-mobile'
                         style={{padding: '2rem 0 1rem 0'}}/>

      <Spin spinning={newsLoading}>
        {
          newsData && (
          <>
            {
              !newsLoading && (            
                <Row justify='center' align='middle' style={{color: 'white', textAlign: 'center'}}> 
                  <Col span={2}/>
                  <Col span={20}>
                    <SectionDivider orientation={'center'}
                                    title={textResult}
                                    fontSize={'1.375rem'}/>
                  </Col>
                  <Col span={2}/>
                </Row>
              )
            }
            <Row justify="center" align="middle">
              <Col span={2}/>
              <Col span={20}>
                  <div style={{paddingTop: '2rem'}}>
                    <CardSection 
                      loading={false}
                      newsData={newsData} 
                      gridInfo={gridInfo} 
                      widthScale={0.8}/>
                    <NewsPagination subscribed={true} 
                        currentPage={currentPage}
                        totalPages={totalPages}
                        handlePaginationClick={handlePaginationClick}/>
                  </div>
              </Col>
              <Col span={2}/>
            </Row>
          </>
          )
        }
      </Spin>
    </div> : <></>}
    </Content>
    <NewsHubFooter/>
    </Layout>
  );
}

export default VisualisationPage;