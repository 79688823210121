import React, {useState, useEffect} from 'react';
import { Row, Col, Layout, Tag, Modal, Tooltip} from 'antd';

import NewsHubFooter from '../main/NewsHubFooter';
import NewsHubHeader from '../main/NewsHubHeader';

import {useParams} from 'react-router-dom'
import {getMainContentSectionMap, getOppThemeColor} from '../../utils/utils'
import '../../css/Common.css'
import { useAutheDataContext } from '../../contexts/AuthDataContext';
import dayjs from 'dayjs'
import "dayjs/locale/en"; 
import { useNavigate } from "react-router-dom";

import api from '../../api/api';
import {DATE_FORMAT, API_URL } from '../../utils/utils';
import { CatDict, CountryContentType } from '../../types';
import { getFirstPart, mapData } from '../../utils/utils';
import CardSection from './CardSection';
import { CARD_PER_PAGE, gridInfo } from './configs';
import NewsPagination from './NewsPagination';
import SearchBox from './SearchBox';
import { useTheme } from '../../contexts/ThemeContext';

const { Content } = Layout;
const mainContentSectionMap = getMainContentSectionMap()

const NewsContent: React.FC<CountryContentType> = ({subscribed}) => {
    const navigate = useNavigate();
    let {country} = useParams()
    const countryCode = country || 'mixed'
    const {authToken} = useAutheDataContext()
    const [totalPages, setTotalPages] = useState<number>(1)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [loading, setLoading] = useState<boolean>(false)
    const [newsData, setNewsData] = useState([])
    const [topics, setTopics] = useState<CatDict>({})
    const [industries, setIndustries] = useState<CatDict>({})
    const [categories, setCategories] = useState<CatDict>({})
    const {theme} = useTheme()
    let headers = authToken ? {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }   
    } : {}
  
    const fetchData = async (queryStr: string, headers: any) => {
      try {
        setLoading(true)
        let res = await api.get(queryStr, headers)
        let displayData = res.data.results
        displayData.map((x: any) => (x.contentSummary = getFirstPart(x.summary)))
        setNewsData(displayData)  
        setTotalPages(res.data.count)     
      } catch (err) {
        console.log('err:', err)
      }
      finally {
        setLoading(false)
      }
    }
  
    const fetchOptions = async () => {
      try {
        let res = await api.get(`${API_URL}/configurations/topics/`, headers)
        const resTopics = mapData(res.data.results)
        setTopics(resTopics)
  
        res = await api.get(`${API_URL}/configurations/industry_sectors/`, headers)
        const resSectors = mapData(res.data.results)
        setIndustries(resSectors)
  
        res = await api.get(`${API_URL}/configurations/categories/`, headers)
        const resCategories = mapData(res.data.results)
        setCategories(resCategories)   
      }
      catch (err) {
        console.log('fetching errors: ', err)
      }
    }
  
    const handlePaginationClick = async (page: number) => {
      if (page !== currentPage) {
        if (!subscribed) {
          Modal.error({
            title: 'Permission Error',
            content: 'Sorry, this feature is only available to premium users'
          })
        } else {
            try {
              let queryStr = `${API_URL}/contents/articles/?page_size=${CARD_PER_PAGE}&${mainContentSectionMap[countryCode].param}&page=${page}`;
              let headers = authToken ? {
                  headers: {
                    'Authorization': `Bearer ${authToken}`
                  }   
              } : {}        
              await fetchData(queryStr, headers)
              setCurrentPage(page)
            } catch (err) {
              console.log(err)
            }
          }  
        }
    }
  
    const [selectedDates, setSelectedDates] =  useState<any>(null)  
    const [selectedCategories, setSelectedCategories] =  useState<any>(undefined)   
    const [selectedTopic, setSelectedTopic] =  useState<any>(undefined)   
    const [selectedIndustry, setSelectedIndustry] =  useState<any>(undefined)   
    const [inputSearchText, setInputSearchText] = useState<string>('')
  
    const resetSearchBox = () => {
      setInputSearchText('')
      setSelectedTopic(undefined)
      setSelectedIndustry(undefined)
      setSelectedCategories(undefined)      
      setSelectedDates(undefined)
    }
    const handlePublishedDateChange = (dates: any) => {
      setSelectedDates(dates)
      handleSearch(inputSearchText, selectedTopic, selectedIndustry, selectedCategories,dates)
    };
  
    const handleCategoryChange = (cats: []) => {
      setSelectedCategories(cats)
      handleSearch(inputSearchText, selectedTopic, selectedIndustry, cats, selectedDates)      
    };
  
    const handleTopicChange = (tps: []) => {
      setSelectedTopic(tps)
      handleSearch(inputSearchText, tps, selectedIndustry, selectedCategories, selectedDates)      
    };
  
    const handleIndustryChange = (industries: []) => {
      setSelectedIndustry(industries)
      handleSearch(inputSearchText, selectedTopic, industries, selectedCategories, selectedDates)          
    };
  
    const handleSearch = async (searchValue: any, topic: any, industry: any, category: any, publishedDates: any) => {
      try {
        let queryStr = `${API_URL}/contents/articles/search/?page_size=${CARD_PER_PAGE}&${mainContentSectionMap[countryCode].param}`;
        let headers = authToken ? {
            headers: {
              'Authorization': `Bearer ${authToken}`
            }   
        } : {}        
        queryStr += searchValue ? `&query=${searchValue}` : ''
        queryStr += topic ? `&topic=${topic}` : ''
        queryStr += industry ? `&industry_sector=${industry}` : ''
        queryStr += category ? `&category=${category}` : ''
        queryStr += publishedDates ? `&published_date_start=${dayjs(publishedDates[0]).format(DATE_FORMAT)}&&published_date_end=${dayjs(publishedDates[1]).format(DATE_FORMAT)}` : ''
        await fetchData(queryStr, headers)
        setCurrentPage(1)        
      } catch (err) {
        console.log(err)
      }
    }
  
    const handleSearchInputChange = (event: any) => {
      setInputSearchText(event.target.value);
      if (event.target.value.length === 0) {
        handleSearch("", selectedTopic, selectedIndustry, selectedCategories, selectedDates)          
      }
    }

    const handleSearchBtnClick = (searchValue: any) => {
      handleSearch(searchValue, selectedTopic, selectedIndustry, selectedCategories, selectedDates);
    }
  
    const handleCountryClick = (btnKey: string) => {
      if (btnKey !== countryCode) {
        if (subscribed) {
          navigate(`/content/premium/${btnKey}`)
        } else {
          navigate(`/content/basic/${btnKey}`)
        }
        
      }
    }

    useEffect(() => {   
      if (authToken) {
        fetchOptions()
        resetSearchBox()
        let queryStr = `${API_URL}/contents/articles/?page_size=${CARD_PER_PAGE}&${mainContentSectionMap[countryCode].param}`;
        let headers = {
            headers: {
              'Authorization': `Bearer ${authToken}`
            }   
          }
        fetchData(queryStr, headers)  
        document.title = `${mainContentSectionMap[countryCode].tabTitle}`      
      } else {
        navigate('/')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryCode])
    
    return (
        <Layout style={{backgroundColor: theme}}>
            <NewsHubHeader/>
            <Content>
              <Row justify='center' align='middle' gutter={[8,8]} style={{padding: '1rem 0 0 0'}}>
                    <Tag 
                            style={{fontWeight: countryCode === 'mixed' ? 900 : 'normal', 
                                    cursor: 'pointer',
                                    color: getOppThemeColor(theme)}}
                            onClick={(e)=>handleCountryClick('mixed')}>
                            <span>Mixed</span>
                    </Tag>                
                    <Tag 
                            style={{fontWeight: countryCode === 'ie' ? 900 : 'normal', 
                                    cursor: 'pointer',
                                    color: getOppThemeColor(theme)}}
                            onClick={(e)=>handleCountryClick('ie')}>
                            <span>AI Ireland</span>
                    </Tag>
                    <Tag 
                            style={{fontWeight: countryCode === 'gb' ? 900 : 'normal',
                            cursor: 'pointer',                            
                            color: getOppThemeColor(theme)}}
                            onClick={(e)=>handleCountryClick('gb')}>
                            <span>AI UK</span>
                    </Tag>
                    <Tag 
                            style={{fontWeight: countryCode === 'us' ? 900 : 'normal',
                            cursor: 'pointer',                            
                            color: getOppThemeColor(theme)}}
                            onClick={(e)=>handleCountryClick('us')}>
                            <span>AI US</span>
                    </Tag>
                    <Tag
                            style={{fontWeight: countryCode === 'eu' ? 900 : 'normal',
                            cursor: 'pointer',                            
                            color: getOppThemeColor(theme)}}
                            onClick={(e)=>handleCountryClick('eu')}>
                            <span>AI EU</span>
                    </Tag>
                    <Tag
                            style={{fontWeight: countryCode === 'ww' ? 900 : 'normal',
                            cursor: 'pointer',                            
                            color: getOppThemeColor(theme)}}
                            onClick={(e)=>handleCountryClick('ww')}>
                            <Tooltip title={<i>AI Worldwide encompasses news converge from rest of the world, excluding specific reporting in the Ireland, US, UK, and EU regions.</i>} key={'ai worldwide'}>
                              <span>AI Worldwide</span>
                            </Tooltip>
                            
                    </Tag>
              </Row>

              <Row justify="center" align="middle" style={{margin: '2rem 0 1rem 0'}}>
                  <Col span={1}/>
                  <Col span={22}>
                    <SearchBox handleSearchBtnClick = {handleSearchBtnClick}
                                              handleSearchInputChange = {handleSearchInputChange}
                                              inputSearchText = {inputSearchText}
                                              selectedTopic = {selectedTopic}
                                              handleTopicChange = {handleTopicChange}
                                              topics = {topics}
                                              selectedIndustry = {selectedIndustry}
                                              handleIndustryChange = {handleIndustryChange}
                                              industries = {industries}
                                              selectedCategories = {selectedCategories}
                                              handleCategoryChange = {handleCategoryChange}
                                              categories = {categories}
                                              selectedDates = {selectedDates}
                                              handlePublishedDateChange = {handlePublishedDateChange}/>
                  <CardSection sectionKey={countryCode} 
                              newsData={newsData} 
                              gridInfo={gridInfo} 
                              loading={loading} 
                              widthScale={0.8}/>
                  {!loading && newsData.length > 0 && <NewsPagination subscribed={subscribed} 
                                currentPage={currentPage}
                                totalPages={totalPages}
                                handlePaginationClick={handlePaginationClick}/>}
                </Col>
                <Col span={1}/>
              </Row>
          </Content>
          <NewsHubFooter/>
        </Layout>
    );
}

export default NewsContent;
