import { useState } from "react";
import { Form, Input, Button, Modal, Alert } from "antd";
import {
  LockOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import api from "../../api/api"

import "../../css/Login.css";
import { Credentials } from "../../types";
import { useAutheDataContext } from "../../contexts/AuthDataContext";
import jwt_decode from 'jwt-decode';
import { DecodedTokenType } from "../../types";
import { mapProfile } from "../../utils/utils";
import EmailNotVerifiedModal from "../EmailNotVerifiedModal";

const API_URL : string = (process.env.REACT_APP_API_URL as string)

interface LoginModalProps {
  open: boolean,
  onCancel: (e: any) => void,
  onOpenSignUp: (e: any) => void,
  onOpenForgotPassword: (e: any) => void
}


const LoginPage: React.FC<LoginModalProps> = ( {open, 
                                                onCancel, 
                                                onOpenSignUp,
                                                onOpenForgotPassword}) => {
  
  const {setAuthToken, setUser, setUserProfile} = useAutheDataContext();
                                                
  const [loading, setLoading] = useState(false);
  const [invalidAlert, setInvalidAlert] = useState(false)
  const [emailNotVerifiedVisible, setemailNotVerifiedVisible] = useState(false);

  const handleEmailModalClose = () => {
    setemailNotVerifiedVisible(false);
  };

  const navigate = useNavigate();

  const onFinish = async (credentials: Credentials) => {
    try {
      setLoading(true)
      const loginRes: any = await api.post(`${API_URL}/auth/token/`, {
        email: credentials.email.toLowerCase(),
        password: credentials.password
      })
      const decodeObj: DecodedTokenType = jwt_decode(loginRes.data.access) as DecodedTokenType      
      setAuthToken(loginRes.data.access)

      setUser({
        userId: decodeObj.user_id,
        email: credentials.email.toLowerCase()
      })

      try {
        const profileRes: any = await api.get(`${API_URL}/profiles/me`, {
          headers: {
            'Authorization': `Bearer ${loginRes.data.access}`
          }   
        })
        setLoading(false)
        setUserProfile(mapProfile(profileRes.data))
        if (profileRes.data.has_subscription){
          navigate(`/personal/premium`)
        } else {
          navigate(`/personal/basic`)
        }
        } catch (err) {
          console.log(`${err} Profile for user id ${loginRes.data.user_id} not found. Navigate to profile update page.`)
          setLoading(false)
          navigate('/users/profile/new')
        }
        onCancel({})
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        const { data } = err.response;
        if (data.detail === 'Email is not verified.' || data.detail === 'Email is not verified and token is expired.') {
          onCancel({})      
          setemailNotVerifiedVisible(true);
        }       
      }
      setLoading(false)
      setInvalidAlert(true)      
    } finally {
      setLoading(false)
    }
  };

  const handleInputChange = () => {
    setInvalidAlert(false)
  }

  return (
    <>
      <Modal
            open={open}
            onCancel={onCancel}
            footer={null}>              
                <Form name="signin" 
                      onFinish={onFinish}>  
                  <h1 className="signin-page__title">Log In</h1>
                  <Form.Item
                    name="email"
                    rules={[{ required: true, message: "Please input your email!" }]}
                  >
                    <Input
                      prefix={<MailOutlined className="site-form-item-icon" />}
                      placeholder="Email"
                      style={{ paddingLeft: 10}}
                      onChange={handleInputChange}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: "Please input your password!" }]}
                  >
                    <Input.Password
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Password"
                      style={{ paddingLeft: 10}}
                      onChange={handleInputChange}
                    />
                  </Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      className="signin-page__submit-button"
                    >
                      Sign In
                    </Button>
                    <div className="signin-page__login-link">
                        <Button 
                          type="link" 
                          onClick={(e) => onOpenForgotPassword(e)}>Forgot Password?</Button>
                    </div>                    
                    <div className="signin-page__login-link">
                      <span>Don't have an account yet?</span>
                      <Button type="link" onClick={(e) => onOpenSignUp(e)}>Sign Up</Button>
                    </div>
                    {invalidAlert && <Alert message="Sorry, the credentials you entered are not valid. Please try again" type="error" />}                    
                </Form>
      </Modal>
      <EmailNotVerifiedModal visible={emailNotVerifiedVisible} onClose={handleEmailModalClose}/>
    </>
  );
};

export default LoginPage;

