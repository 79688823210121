import React, {useState, useEffect} from 'react';
import {Button, Row, Col, message} from 'antd';
import { useNavigate } from 'react-router-dom';
import logo from '../../images/newshub-logo-main.png';
import {MenuOutlined} from '@ant-design/icons'
import '../../css/Header.css'; 
import '../../css/Common.css'; 

import { useAutheDataContext } from '../../contexts/AuthDataContext';
import { DesktopMenuHeader } from '../main/DesktopMenuHeader';
import { MobileMenuHeader } from './MobileMenuHeader';
import jwtDecode from 'jwt-decode';
import { DecodedTokenType } from '../../types';
import { ContentMenu } from './ContentMenu';
import { removeTabIndex } from '../../utils/utils';

const HEADER_BACKGROUND = "black"

const NewsHubHeader: React.FC = () => {
  const navigate = useNavigate()

  const {
         userProfile, 
         setUser, 
         setUserProfile,
         authToken,
         setAuthToken} = useAutheDataContext()
  
  const handleLogout = () => {
    setAuthToken(null)
    setUser(null)
    setUserProfile(null)
    localStorage.removeItem("authToken")
    localStorage.removeItem("user")
    localStorage.removeItem("userProfile")
    navigate('/')
  }

  const handleHomeClick = (e: any) => {
    navigate('/')    
    removeTabIndex(e)
  }

  const handleAboutClick = (e: any) => {
    navigate('/about')
    removeTabIndex(e)
  }

  const handleShowPersonalised = () => {
    if (userProfile?.hasSubscription) {
      navigate(`/personal/premium`)
    } else {
      navigate(`/personal/basic`)
    }
  }

  const handleShowNews = () => {
    if (userProfile?.hasSubscription) {
      navigate(`/content/premium/${'mixed'}`)
    } else {
      navigate(`/content/basic/${'mixed'}`)
    }
  }

  const handleShowAnalytics = () => {
    navigate(`/analytics`)
  }

  const handleWhitePaper = () => {
    navigate(`/whitepaper`)
  }

  const handleSettingsClick = (e: any) => { 
    navigate('/users/profile/update')
    removeTabIndex(e)
  }
  
  const [visible, setVisible] = useState(false)
  const mobileHeaderClose = () => {
    setVisible(false)
  }

  const handleMenuIconClick = (e: any) => {
    setVisible(true)
  }

  const PREORDIC_CHECK_TIME = 120000
  
  useEffect(() => {
    if (authToken) {
      const decodedToken: DecodedTokenType = jwtDecode(authToken) as DecodedTokenType
      const currentTime = Date.now() / 1000

      if (decodedToken.exp < currentTime)  {
            handleLogout()
            return
      } else {
          const intervalId = setInterval(() => {
            const currentTime = Date.now() / 1000
            if (decodedToken.exp < currentTime)  {
                  handleLogout()
                  message.info('Your session is expired. Please login again!')
            }
          }, PREORDIC_CHECK_TIME)
          return () => clearInterval(intervalId)      
      } 
    }
  })
  
  return (
    <>
      <div className="header-desktop-div">
          <Row style={{background: HEADER_BACKGROUND}} 
               justify="center" 
               align="middle">
              <Col flex={1} style={{textAlign: "center"}}>
                <Row>
                  <Col flex={1}/>
                  <Col flex={1}>
                    <img src={logo} alt="logo" width="140px" onClick={() => navigate('/')} style={{cursor: "pointer"}}/>      
                  </Col>
                  <Col flex={1}/>
                </Row>
                <Row>
                <Col flex={1}/>
                <Col flex={1}>
                  <a href="https://ceadar.ie" target="_blank" rel="noreferrer" style={{color: "white", background: HEADER_BACKGROUND}}>
                    <strong>
                      Powered by CeADAR
                    </strong>
                  </a>
                </Col>
                <Col flex={1}/>
                </Row>                
                <Row>
                </Row>  
              </Col>                
              <Col flex={1} style={{paddingBottom: '30px', paddingTop: '20px'}}>
                <div className='desktop-header-menu'>
                  <DesktopMenuHeader 
                      handleHomeClick = {handleHomeClick}
                      handleAboutClick = {handleAboutClick}
                      handleSettingsClick = {handleSettingsClick}
                      handleLogout = {handleLogout}/>
                </div>
              </Col>  
              
          </Row>        
          {authToken && (
            <ContentMenu handleShowPersonalised={handleShowPersonalised}
                         handleShowNews={handleShowNews}
                         handleShowAnalytics={handleShowAnalytics}
                         handleWhitePaper={handleWhitePaper}/>
          )}
      </div>

      <div className="header-mobile-div">
          <Row style={{background: HEADER_BACKGROUND}} justify="center" align="middle">
              <Col flex={1}/>
              <Col flex={3} style={{textAlign: 'center', paddingBottom: '30px', paddingTop: '20px'}}>
              <Row>
                  <Col flex={1}/>
                  <Col flex={1}>
                    <img src={logo} alt="logo" width="140px" onClick={() => navigate('/')} style={{cursor: "pointer"}}/>      
                  </Col>
                  <Col flex={1}/>
                </Row>
                <Row>
                <Col flex={1}/>
                <Col flex={1}>
                  <a href="https://ceadar.ie" target="_blank" rel="noreferrer" style={{color: "white", background: HEADER_BACKGROUND}}>
                    <strong>
                      Powered by CeADAR
                    </strong>
                  </a>
                </Col>
                <Col flex={1}/>
                </Row>                
                <Row>
                </Row>  
              </Col>  
              <Col flex={1}>
                <Button type="primary"  onClick={(e: any)=> handleMenuIconClick(e)}><MenuOutlined/></Button>
              </Col>
              <MobileMenuHeader 
                      handleHomeClick = {handleHomeClick}
                      handleAboutClick = {handleAboutClick}
                      handleSettingsClick = {handleSettingsClick}
                      handleLogout = {handleLogout}
                      visible={visible}
                      mobileHeaderClose={mobileHeaderClose}
                    />
          </Row>        
          {authToken && (
            <ContentMenu  handleShowPersonalised={handleShowPersonalised}
                          handleShowNews={handleShowNews}
                          handleShowAnalytics={handleShowAnalytics}
                          handleWhitePaper={handleWhitePaper}/>
            )}
      </div>
    </>
  ) 
};

export default NewsHubHeader;
