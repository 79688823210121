import { API_URL } from '../utils/utils'
import api from './api'

const buildHeader = (authToken: string) => {
  return {
    headers: {
      'Authorization': `Bearer ${authToken}`
    }   
  }
}
export const verifyToken = (authToken: string) => {
  const dataObj = {
    token: authToken
  }
  return api.post(`${API_URL}/auth/token/verify/`, dataObj)
}

export const getUser = (authToken: string) => api.get(`${API_URL}/users/me`, buildHeader(authToken))

export const getUserProfile = (authToken: string) => api.get(`${API_URL}/profiles/me`, buildHeader(authToken))
