import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import Cookies from 'universal-cookie';
import '../../css/Common.css'
const CookieConsent: React.FC = () => {
  const cookies = new Cookies();
  const [visible, setVisible] = useState(!cookies.get('consent'));

  const handleAccept = () => {
    cookies.set('consent', 'true', { path: '/' });
    setVisible(false);
  };

  const handleAllowNecessary = () => {
    cookies.set('consent', 'necessary', { path: '/' });
    setVisible(false);    
  };

  return (
    <Modal
      open={visible}
      centered
      closable={false}
      footer={[
        <Button key="allowAll" type="primary" onClick={handleAccept}>
            Allow all
        </Button>,
        <Button key="allowNecessary" type="primary" onClick={handleAllowNecessary}>
            Allow necessary
        </Button>,
      
      ]}

    >
      <p>By using this website, you consent to our use of cookies. 
        For more information on cookies, 
        please see the <a href='https://ceadar.ie/cookie-policy/' target='_blank' rel="noreferrer">CeADAR's Cookie Policy</a> page.</p>
    </Modal>
  );
};

export default CookieConsent;
