import { Col, Collapse, DatePicker, Input, Row, Select } from 'antd';
import React, { useState } from 'react'
import {SearchOutlined} from "@ant-design/icons";
import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs'

const {Option} = Select
const {RangePicker} = DatePicker;
const {Panel} = Collapse;

interface SearchBoxType {
    handleSearchBtnClick: any
    handleSearchInputChange: any
    inputSearchText: any
    selectedTopic: any
    handleTopicChange: any
    topics: any
    selectedIndustry: any
    handleIndustryChange: any
    industries: any
    selectedCategories: any
    handleCategoryChange: any
    categories: any
    handlePublishedDateChange: any
    selectedDates: any    
}
const SearchBox: React.FC<SearchBoxType> = ({
                                                handleSearchBtnClick,
                                                handleSearchInputChange,
                                                inputSearchText,
                                                selectedTopic,
                                                handleTopicChange,
                                                topics,
                                                selectedIndustry,
                                                handleIndustryChange,
                                                industries,
                                                selectedCategories,
                                                handleCategoryChange,
                                                categories,
                                                handlePublishedDateChange,
                                                selectedDates
                                            }) => {
                                                    
    const disabledDate: RangePickerProps['disabledDate'] = (current: any) => {
        return current && current > dayjs().endOf('day');
        };    

    const [showSearchBox, setShowSearchBox] = useState(false);
    const toggleSearchBox = () => {
        setShowSearchBox(!showSearchBox);
      };
 
    return (
        <Row justify='center' align='middle' style={{marginBottom: '1rem'}}>
            <Collapse activeKey={showSearchBox ? '1' : '0'} 
                      className='search-collapse'
                      size='small'>
                <Panel
                    header={
                        <div>
                            <div onClick={toggleSearchBox} style={{ cursor: 'pointer', textAlign: 'left'}}>
                                <strong  style={{ marginRight: 8, color: 'white'}} className={'menu-text'}>
                                    <SearchOutlined/> Search
                                </strong>
                            </div>
                        </div>
                    }
                    key="1">
                    <Row justify="center" align="middle">
                        <Col span={24}>
                            <Row gutter={[16,16]}  style={{marginBottom: '1rem'}}>
                                <Col span={24}>
                                    <Input.Search
                                        placeholder={`Input Search Text`}
                                        allowClear
                                        enterButton={<SearchOutlined />}
                                        onSearch={handleSearchBtnClick}
                                        onChange={handleSearchInputChange}
                                        value={inputSearchText}
                                        />
                                </Col>
                            </Row>

                            <Row gutter={[16,16]}>
                                <Col xs={24} sm={24} md={12} lg={6}>
                                    <Select placeholder="Topics" 
                                    value={selectedTopic}
                                    onChange={handleTopicChange} 
                                    allowClear
                                    mode="multiple"
                                    style={{width: "100%"}}>
                                        { 
                                        Object.entries(topics).map(([topicId, topicName]: any) => (
                                            <Option key={topicId} value={topicId}>{topicName}</Option>
                                        ))
                                        }
                                    </Select>                
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={6}>
                                    <Select placeholder="Industries" 
                                    value={selectedIndustry}
                                    onChange={handleIndustryChange} 
                                    allowClear
                                    mode="multiple"
                                    style={{width: "100%"}}>
                                    {
                                        Object.entries(industries).map(([industryId, industryName]: any)=> (
                                        <Option key={industryId} value={industryId}>{industryName}</Option>
                                        ))                      
                                    }
                                    </Select>                 
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={6}>
                                    <Select placeholder="Categories"
                                    value={selectedCategories} 
                                    onChange={handleCategoryChange} 
                                    allowClear
                                    mode="multiple"
                                    style={{width: "100%"}}>
                                    { 
                                    Object.entries(categories).map(([categoryId, categoryName]: any)=> (
                                        <Option key={categoryId} value={categoryId}>{categoryName}</Option>
                                    ))
                                    }
                                    </Select>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={6}>
                                    <RangePicker defaultValue={selectedDates} 
                                    placeholder={["From", "To"]}
                                    onChange={handlePublishedDateChange}
                                    disabledDate={disabledDate}
                                    style={{width: "100%"}}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
      </Row> 
    )                      
}

export default SearchBox;