import { Tag, Tooltip } from "antd";

interface TagTooltipType {
    name: any,
    desc: string,
    color: string
}

const tagStyle: any = {
    whiteSpace: "break-spaces",
    fontSize: '0.7rem'
}
const TagTooltip: React.FC<TagTooltipType> = ({name, desc, color}) => {
    return (
        <Tooltip title={<strong><i>{desc}</i></strong>}>
            <Tag  id="myTag" color={color} style={tagStyle}><strong>{name}</strong></Tag>
        </Tooltip>                     
    )
}

export default TagTooltip;