import React from 'react';
import '../../css/Header.css'; 
import '../../css/Common.css'; 

import LoginPage from '../users/Login';
import SignUpPage from '../users/Signup';
import ForgotPasswordForm from '../users/ForgotPassword';
import { removeTabIndex } from '../../utils/utils';
interface UserModalsTypes {
        openLogin: boolean,
        setOpenLogin: (value: boolean) => void
        openSignUp: boolean,
        setOpenSignUp: (value: boolean) => void
        openForgotPassword: boolean
        setOpenForgotPassword: (value: boolean) => void
}

export const UserModals: React.FC<UserModalsTypes> = ({openLogin, 
                                                       setOpenLogin,
                                                       openSignUp, 
                                                       setOpenSignUp,
                                                       openForgotPassword,
                                                       setOpenForgotPassword}) => {

        const handleOpenLogin = (e: any) => {
                setOpenLogin(true)
                setOpenSignUp(false)
                setOpenForgotPassword(false)        
                removeTabIndex(e)        
        }

        const handleCloseLogin = (e: any) => {
                setOpenLogin(false)
                removeTabIndex(e)        
        }

        const handleOpenSignUp = (e: any) => {
                setOpenLogin(false)
                setOpenSignUp(true)
                removeTabIndex(e)        
        }

        const handleCloseSignUp = (e: any) => {
                setOpenSignUp(false)
                removeTabIndex(e)        
        }

        const handleOpenForgotPassword = (e: any) => {
                setOpenLogin(false)
                setOpenForgotPassword(true)
        }

        const handleCloseForgotPassword = (e: any) => {
                setOpenForgotPassword(false)
        }

                                                                
        return (
                <>
                        {openLogin}
                        <LoginPage open={openLogin} 
                                        onCancel={(e: any) => handleCloseLogin(e)}
                                        onOpenSignUp = {(e: any) => handleOpenSignUp(e)}
                                        onOpenForgotPassword = {(e: any) => handleOpenForgotPassword(e)}/>

                        <ForgotPasswordForm open={openForgotPassword} 
                                                onOpenLogin = {(e:any) => handleOpenLogin(e)} 
                                                onCancel={(e: any) => handleCloseForgotPassword(e)}/>

                        <SignUpPage open={openSignUp} 
                                        onOpenLogin = {(e:any) => handleOpenLogin(e)}          
                                        onCancel={(e: any) => handleCloseSignUp(e)}/>
                </>    
        ) 
};