import { CatDict, CountryCodeMap, UserSectionMap} from "../types"
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';

dayjs.extend(duration);
dayjs.extend(utc);

const MAX_PARA_LENGTH = 20

export const calculateMenuWidth = (inputWidth: number) => {
    if (inputWidth <= 280) return "80%"
    else if (inputWidth > 280 && inputWidth <= 360) return "60%"
    else if (inputWidth > 360 && inputWidth <= 414) return "50%"
    else if (inputWidth > 414 && inputWidth >= 540) return "40%"
    else if (inputWidth > 540 && inputWidth <= 767) return "30%"
    else if (inputWidth >= 767 && inputWidth <= 912) return "15%"
    else return "10%"
}

const compareByPublishedDate = (a: any, b: any) => {
  const dateA = new Date(a.published_date);
  const dateB = new Date(b.published_date);
  return dateB.getTime() - dateA.getTime();
}

export const sortByPublishedDate = (arr: any) => {
  return arr.sort(compareByPublishedDate)
}

export const sortByPreference = (userProfile: any, arr: any) => {
    const marked: boolean[] = new Array(arr.length).fill(false)
    let res = []

    for (let i = 0; i < arr.length ;i++) {
        if (!marked[i]) {
            const objCats = arr[i].topics.map((elm: any) => elm.id)
            if (userProfile.topics.some((x: any) => objCats.includes(x))) {
            res.push(arr[i])
            marked[i] = true
            }  
        }
    }

    for (let i = 0; i < arr.length; i++) {
        if (!marked[i]) {
            const objCats = arr[i].industry_sectors.map((elm: any) => elm.id)
            if (userProfile.industries.some((x: any) => objCats.includes(x))) {
            res.push(arr[i])
            marked[i] = true
            }  
        }
    }

    for (let i = 0; i < arr.length ;i++) {
      if (!marked[i]) {
          const objCats = arr[i].publisher.categories.map((elm: any) => elm.id)
          if (userProfile.categories.some((x: any) => objCats.includes(x))) {
          res.push(arr[i])
          marked[i] = true
          }  
      }
    }

    for (let i = 0; i < arr.length ;i++) {
      if (!marked[i]) {
        marked[i] = true
        res.push(arr[i])
      }
    }

    return res;
  }



  // for search
  export const mapData = (arr: any) => {
    const res: CatDict = {}
    for (let i = 0; i < arr.length; i++) {
      res[arr[i].id] = arr[i].name
    }
    return res
  }

export  const getFirstPart = (paragraph: string) => {
    if (paragraph && paragraph !== "nan") {
      const words = paragraph.split(' ')
      let someFirstWords = words.slice(0, MAX_PARA_LENGTH).join(' ')
      someFirstWords += '...'
      return someFirstWords
    } else {
      return paragraph
    }
}

export const homeSectionMap: UserSectionMap = {
  "ie": {
    "param": "&country=IE",
    "sectionTitle": "AI Ireland",
    "trendingTitle": "Ireland",
    "tabTitle": "AI NewsHub | Ireland"
  },
  "gb": {
    "param": "&country=GB",
    "sectionTitle": "AI UK",
    "trendingTitle": "UK",
    "tabTitle": "AI NewsHub | UK"
  },
  "us": {
    "param": "&country=US",
    "sectionTitle": "AI USA",
    "trendingTitle": "USA",
    "tabTitle": "AI NewsHub | USA"
  },
  "eu": {
    "param": "&country=EU",
    "sectionTitle": "AI EU",
    "trendingTitle": "EU",
    "tabTitle": "AI NewsHub | EU"
  },
  "ww": {
    "param": "&country=IE,GB,US",
    "sectionTitle": "AI Worldwide",
    "trendingTitle": "Worldwide",
    "tabTitle": "AI NewsHub | Worldwide"
  }
}

export const getMainContentSectionMap = () => {
  const mixedObj = {
    "param": "",
    "sectionTitle": "All AI News",
    "trendingTitle": "Ireland",
    "tabTitle": "AI NewsHub | Mixed"
  };
  
  let tmp: UserSectionMap = {...homeSectionMap}
  tmp["mixed"] = mixedObj;
  return tmp;  
}

export const countryCodeMap: CountryCodeMap = {
  "IE": "IE",
  "GB": "UK",
  "US": "USA",
  "IE,GB,US": "Worldwide",
  "EU": "EU"
}
export const DATE_FORMAT = 'YYYY-MM-DD'

export const API_URL : string = (process.env.REACT_APP_API_URL as string)

export const IMAGE_PLACE_HOLDER = "https://picsum.photos/200/300"

const mainContentSectionMap = getMainContentSectionMap()

export const getotherCountries = (selectedCountry: string) => {
  const sectionKeys = [...Object.keys(mainContentSectionMap)]
  return sectionKeys.filter((elm) => elm !== selectedCountry)
}

export const buildQueryStrArr = () => {
  const sectionKeys = [...Object.keys(mainContentSectionMap)]
  return sectionKeys.map((sectionKey) => `${API_URL}/contents/articles/${mainContentSectionMap[sectionKey].param}`);
}

export const mapUser = (serverUserFormat: any) => {
  return {
    userId: serverUserFormat.id,
    email: serverUserFormat.email
  }
}

export const mapProfile = (serverProfileFormat: any) => {
  return {
    profileId: serverProfileFormat.id, 
    hasSubscription: serverProfileFormat.has_subscription,
    gender: serverProfileFormat.gender ? serverProfileFormat.gender : null, 
    jobTitle: serverProfileFormat.role_job_title ? serverProfileFormat.role_job_title : null, 
    dateOfBirth: serverProfileFormat.date_of_birth ? serverProfileFormat.date_of_birth : null, 
    company: serverProfileFormat.company, 
    topics: serverProfileFormat.topic_of_interests, 
    industries: serverProfileFormat.industry_sectors, 
    categories: serverProfileFormat.categories, 
    frequency: serverProfileFormat.frequency, 
    firstName: serverProfileFormat.first_name,
    lastName: serverProfileFormat.last_name,
  }
}

export function getRandomIntInRange(min: number, max: number): number {
  // Calculate the range of values
  const range = max - min + 1;
  
  // Generate a random number between 0 and 1
  const randomDecimal = Math.random();
  
  // Scale the random number to the desired range
  const scaledValue = randomDecimal * range;
  
  // Shift the scaled value by the minimum to get the final random integer
  const randomInt = Math.floor(scaledValue) + min;
  
  return randomInt;
}

export function getTimeDistanceFromNow(targetDate: any): string {
  const now = dayjs();
  const target = dayjs(targetDate);

  const duration = dayjs.duration(now.diff(target));
  const days = duration.asDays();
  const hours = duration.asHours();
  const minutes = duration.asMinutes();
  const seconds = duration.asSeconds();
  const months = duration.asMonths();

  if (months >= 1) {
    return `${Math.floor(months)}mo`;
  } else if (days >= 1) {
    return `${Math.floor(days)}d`;
  } else if (hours >= 1) {
    return `${Math.floor(hours)}h`;
  } else if (minutes >= 1) {
    return `${Math.floor(minutes)}m`;
  } else {
    return `${Math.floor(seconds)}s`;
  }
}

export function calculateCardHeight(setCardHeight: any,  innerWidth: any) {
  let size = null;
  
  if ((innerWidth >= 1565) || 
      (innerWidth < 575 && innerWidth >=391)) size = 22.5
  else if (innerWidth < 1565 && innerWidth >= 1162) size = 25
  else if ((innerWidth < 768 && innerWidth >= 575) || 
           (innerWidth < 391)) size = 23.8
  else if (innerWidth < 1162 && innerWidth >= 768) size = 26.5
  
  // if (innerWidth >= XXL) size = 22.5
  // else if (innerWidth < XXL && innerWidth >= XL) size = 25.5
  // else if (innerWidth < XL && innerWidth >= LG) size = 25.5
  // else if (innerWidth < LG && innerWidth >= MD) size = 25.5
  // else if (innerWidth < MD && innerWidth >= SM) size = 25.5
  // else if (innerWidth < SM) size = 26.5

  setCardHeight(size)
  return size
}

export const getOppThemeColor = (theme: 'black' | 'white') => {
  return (theme === 'black')? 'white' : 'black'
}

export const generateColors = (objects: any): string[] => {
  let hash: number = 0;
  let colors: string[] = []  
  for (const object of objects) {
    hash = hash + object.name.charCodeAt(0) + ((hash << 5) - hash);
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xFF;
      color += value.toString(16).padStart(2, '0');
    }
    colors.push(color);
  }
  return colors;
};

export const removeTabIndex = (e: any) => {
  try {
    e.target.removeAttribute('tabIndex')  
} catch (err) {}  
}

export enum ChartType {
  WordCloud = 0,
  Publisher = 1,
  Industry = 2,
}

export enum JumpStep {
  NEXT = 1,
  PREV = -1
}

export const durationMap = {
  last_30_days: {
    text: 'Last 30 Days',
    startDate: dayjs().subtract(30, 'day'),
    endDate: dayjs()
  },
  last_3_months: {
    text: 'Last 3 Months',
    startDate: dayjs().subtract(3, 'month'),
    endDate: dayjs()
  },
  last_6_months: {
    text: 'Last 6 Months',
    startDate: dayjs().subtract(6, 'month'),
    endDate: dayjs()
  },
  last_year: {
    text: 'Last Year',
    startDate: dayjs().subtract(1, 'year'),
    endDate: dayjs()
  },  
}
