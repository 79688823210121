import { Col, Layout, Row } from 'antd';
import '../../css/Banner.css';
import '../../css/WhitePaper.css'
import React from 'react'
import { PaperDownloadButton } from './PaperDownloadButton';
import NewsHubHeader from '../main/NewsHubHeader';
import { useTheme } from '../../contexts/ThemeContext';
import NewsHubFooter from '../main/NewsHubFooter';
import { getOppThemeColor } from '../../utils/utils';
import WhitePaperDesc from './WhitePaperDesc';

const {Content} = Layout


const WhitePaperSection: React.FC = () => {
    const {theme} = useTheme()

    return (
        <Layout style={{backgroundColor: theme}}>
        <NewsHubHeader/>
        <Content>
            <Row align='middle' justify="center">
             <Col xs={24} sm={24} md={24} lg={24} xl={2} xxl={2}/>
             <Col xs={24} sm={24} md={24} lg={24} xl={10} xxl={10} 
             className="whitepaper-photo-tab"
             style={{backgroundSize: (window.innerWidth <= 1024) ? (0.8 * window.innerWidth) : (0.3 * window.innerWidth)}}/>
             <Col xs={24} sm={24} md={24} lg={24} xl={10} xxl={10}>
                <Row>
                    <div style={{color: getOppThemeColor(theme)}}>
                        <WhitePaperDesc/>
                    </div>
                </Row>
                <Row align='middle' justify="center">
                    <PaperDownloadButton text={'Free Download'}/>
                </Row>
                 
             </Col>
             <Col xs={24} sm={24} md={24} lg={24} xl={2} xxl={2}/>
         </Row>

        </Content>
        <NewsHubFooter/>
        </Layout>
      );    
}

export default WhitePaperSection;
