import React, {useState} from 'react';
import { Button } from 'antd';
import '../../css/Banner.css';
import '../../css/WhitePaper.css';

import LoginPage from '../users/Login';
import SignUpPage from '../users/Signup';
import ForgotPasswordForm from '../users/ForgotPassword';

import '../../css/Common.css'
import { useAutheDataContext } from '../../contexts/AuthDataContext';
import { removeTabIndex } from '../../utils/utils';
import WhitePaperHome from '../whitepaper/WhitePaperHome';

const TITLE = "Discover the Latest in AI and Stay Ahead of the Curve"
const CONTENT = "Reliable AI News Anywhere, Anytime"


interface BannerType {
  scrollTo: () => void
}
const Banner: React.FC<BannerType> = ({scrollTo}) => {
  const [openLogin, setOpenLogin] = useState(false)
  const {user} = useAutheDataContext()

  const handleOpenLogin = (e: any) => {
    setOpenLogin(true)
    setOpenSignUp(false)
    setOpenForgotPassword(false)
    removeTabIndex(e)
  }

  const handleCloseLogin = (e: any) => {
    setOpenLogin(false)
    removeTabIndex(e)
  }

  const [openSignUp, setOpenSignUp] = useState(false)

  const handleOpenSignUp = (e: any) => {
    setOpenLogin(false)
    setOpenSignUp(true)
    removeTabIndex(e)
  }

  const handleCloseSignUp = (e: any) => {
    setOpenSignUp(false)
    removeTabIndex(e)  
  }

  const [openForgotPassword, setOpenForgotPassword] = useState(false)

  const handleOpenForgotPassword = (e: any) => {
    setOpenLogin(false)
    setOpenForgotPassword(true)
  }

  const handleCloseForgotPassword = (e: any) => {
    setOpenForgotPassword(false)
  }

  return (
    <div>
        <div id="banner" className="banner">
          <h1 key="title" className="hero-font-title">{TITLE}</h1>
          <h2 key="content" className="hero-font-content text-padding">{CONTENT}</h2>
          {(user === null) && (   
              <Button
              type="primary"
              key="button"
              onClick={(e: any) => handleOpenSignUp(e)}
              className="hero-button">
                <span>Personalise Your AI Experience Now</span>
              </Button>
          )}
          <div className="whitepaper-container">
            <WhitePaperHome/>
          </div>
        </div>
      {
          <>
            <LoginPage open={openLogin} 
                       onCancel={(e: any) => handleCloseLogin(e)}
                       onOpenSignUp = {(e: any) => handleOpenSignUp(e)}
                       onOpenForgotPassword = {(e: any) => handleOpenForgotPassword(e)}/>

            <ForgotPasswordForm open={openForgotPassword} 
                                onOpenLogin = {(e:any) => handleOpenLogin(e)} 
                                onCancel={(e: any) => handleCloseForgotPassword(e)}/>

            <SignUpPage open={openSignUp} 
                        onOpenLogin = {(e:any) => handleOpenLogin(e)}          
                        onCancel={(e: any) => handleCloseSignUp(e)}/>
          </>
        }
    </div>
  );
}

export default Banner;
