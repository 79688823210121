import { Col, Layout, Modal, Row, Spin } from 'antd';
import '../../css/Common.css'
import React, {useEffect, useState} from 'react';
import CardSection from './CardSection';
import { useAutheDataContext } from '../../contexts/AuthDataContext';
import { API_URL, buildQueryStrArr, getFirstPart } from '../../utils/utils';
import api from '../../api/api';
import SectionDivider from '../SectionDivider';
import NewsPagination from './NewsPagination';
import NewsHubHeader from '../main/NewsHubHeader';
import NewsHubFooter from '../main/NewsHubFooter';
import { useNavigate } from "react-router-dom";
import { gridInfo } from './configs';
import { useTheme } from '../../contexts/ThemeContext';

const {Content} = Layout

interface PersonalisationType {
  subscribed: boolean
}

const CARD_PER_PAGE = 36

const Personalisation: React.FC<PersonalisationType> = ({subscribed}) => {
  const {authToken, userProfile} = useAutheDataContext()
  const [latestNews, setLatestNews] = useState<any[]>([])
  const [allNews, setAllNews] = useState<any[]>([])
  const [totalPages, setTotalPages] = useState<number>(1)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(false)
  const maxLength = 1
  const navigate = useNavigate()
  const {theme} = useTheme()
  
  const fetchDataAllRegions = async (queryStrArr: any) => {
    try {
      setLoading(true)
      let headers = authToken ? {
        headers: {
        'Authorization': `Bearer ${authToken}`
        }   
      } : {}      
      const promises = queryStrArr.map((queryStr: any) => api.get(queryStr, headers).then(res => res.data.results));
      const data = await Promise.all(promises);
      let tmp: any[] = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].length >= maxLength) {
        tmp = [...tmp, ...data[i].slice(0, maxLength)]
      } else {
        tmp = [...tmp, ...data[i]]
      }
      }
      tmp.map((x: any) => (x.contentSummary = getFirstPart(x.summary)))
      setLatestNews(tmp)
    } catch (err) {
      console.log('err:', err)
    }
    finally {
     setLoading(false)
    }
}

  const fetchByPreferences = async (page: number) => {
    try {
      const queryStr = `${API_URL}/contents/articles/personalised/?page_size=${CARD_PER_PAGE}&page=${page}`;
      let headers = authToken ? {
          headers: {
            'Authorization': `Bearer ${authToken}`
          }   
        } : {}
  
      setLoading(true)

      let res = await api.get(queryStr, headers)
      let displayData = res.data.results
      setTotalPages(res.data.count)     

      if (displayData.length > 0) {
        displayData.map((x: any) => (x.contentSummary = getFirstPart(x.summary)))
        setAllNews(displayData)
      }
    } catch (err) {
      console.log('err:', err)
    }
    finally {
      setLoading(false)
    }
  } 

  const handlePaginationClick = async (page: number) => {
    if (page !== currentPage) {
      if (!subscribed) {
        Modal.error({
          title: 'Permission Error',
          content: 'Sorry, this feature is only available to premium users'
        })
      } else {
        try {
          await fetchByPreferences(page)
          setCurrentPage(page)
        } catch (err) {
          console.log(err)
        } 
      }
    }      
  }

  useEffect(() => {   
    if (authToken) {
      fetchDataAllRegions(buildQueryStrArr())
      fetchByPreferences(1)
      document.title = "AI NewsHub | Personalisation"  
    } else {
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout style={{backgroundColor: theme}}>
    <NewsHubHeader/>
    <Content>
      {authToken ? (        
        <Row justify="center" align="middle" style={{margin: '2rem 0 0 0'}}>
            <Col span={2}/>
            <Col span={20}>
              <span className="section-title" style={{color: "#00b4d8"}}>Welcome, {userProfile?.firstName}!</span>
              <Spin spinning={loading}>
                {latestNews.length > 0 && 
                <>
                  <SectionDivider orientation={'center'} 
                                  title= {'Featured News'}
                                  fontSize={null}
                                  className={"long-title-divider"}/>

                  <CardSection sectionKey={"personalised"}
                    newsData={latestNews} 
                    gridInfo={gridInfo} 
                    loading={loading}
                    widthScale={1}/>
                </>
                }
                            
                {allNews.length > 0 ? (
                  <>
                    <SectionDivider orientation={'center'} 
                                    title= {`${userProfile?.firstName}! Here's Your Personalised Feed!`}
                                    fontSize={null}
                                    className={"long-title-divider"}/>
                    <CardSection sectionKey={"personalised"} 
                    newsData={allNews} 
                    gridInfo={gridInfo} 
                    loading={loading}
                    widthScale={1}/>
                    <NewsPagination subscribed={subscribed} 
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    handlePaginationClick={handlePaginationClick}/>
                  </>                
                ) : <></>
                }
              </Spin>
            </Col>
            <Col span={2}/>
          </Row>
        ) : <></>
      }

    </Content>
    <NewsHubFooter/>
</Layout>
  );
};

export default Personalisation;

