import { useEffect, useState } from "react"
import PieChart from "../pieChart/PieChart"
import { SearchCriteria } from "../../../types"
import { useAutheDataContext } from '../../../contexts/AuthDataContext';
import { fetchPieData } from "../dataFetcher"
import { ChartTypes } from "../types"
import { Spin } from "antd";
import {Dayjs} from 'dayjs'

interface IndustryStatsType {
    handleSearch: (options: SearchCriteria) => void,
    durationId: string,
    startDate: Dayjs, 
    endDate: Dayjs
}

export const IndustryStats: React.FC<IndustryStatsType> = ({handleSearch, 
                                                            startDate, 
                                                            endDate, 
                                                            durationId}) => {
    const {authToken} = useAutheDataContext()
    const [pieData, setPieData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)
                                                                       
    const handleDonutItemClick = (obj: any) => {
        handleSearch({
            industry: obj.data.id,
            displayText: obj.data.name            
        })
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const res = await fetchPieData(ChartTypes.SECTORS, startDate, endDate, durationId, authToken)
                setPieData(res)
            } catch (e: any) {
                console.log('e: ',e)
            } finally {
                setLoading(false)
            }

        }    
        fetchData()
    },[startDate, endDate, authToken, durationId])
    
    return <Spin spinning={loading}> {
        pieData && <PieChart data = {pieData} 
                handleDonutItemClick = {handleDonutItemClick} 
                x={'count'} 
                y={'name'}
                content={''}/>
        }
    </Spin>
}