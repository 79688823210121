import React, { useState } from "react";
import { Form, Input, Button, message, Modal } from "antd";
import { UserOutlined } from "@ant-design/icons";
import api from "../../api/api"

const API_URL : string = (process.env.REACT_APP_API_URL as string)

interface ForgotPasswordModalProps {
  open: boolean,
  onOpenLogin: (e: any) => void,
  onCancel: (e: any) => void
}

const ForgotPasswordForm: React.FC<ForgotPasswordModalProps> = ({open, 
                                                                 onOpenLogin,
                                                                 onCancel}) => {
  const [messageApi, contextHolder] = message.useMessage()
  const [loading, setLoading] = useState(false);

  const handleForgotPassword = async (value: any) => {
    try{
      setLoading(true);
      // await api.get(`${API_URL}/user_exists/${value.email}`)
      await api.post(`${API_URL}/password_reset/`, { 
        email: `${value.email}`
      }).then(() => {
        messageApi.open({
          type: 'success',
          content: "A reset password link has been sent to the provided email address. Please follow the instruction to reset the password.",
          duration: 5
        });
      }).catch((err: any) => {
        messageApi.open({
          type: 'error',
          content: err.message,
          duration: 2
        });
      })
    // send token
    } catch (err: any) {
      if (err.response.data.exists === false) {
        messageApi.open({
          type: 'error',
          content: "Sorry. This email is not found in our system",
          duration: 2
        });  
      } else {
        messageApi.open({
          type: 'error',
          content: err.message,
          duration: 2
        });  
      }
    }
    finally {
      setLoading(false);
    }
  };

  return (
      <Modal
        open={open}
        onCancel={onCancel}
        footer={null}>
        <Form onFinish={handleForgotPassword}>
        <h1 className="signin-page__title">Enter your email to reset password</h1>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email address",
              },
              {
                type: "email",
                message: "Please input a valid email address",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined />}
              placeholder="Email"
              disabled={loading}
            />
          </Form.Item>
          
          {contextHolder}
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} block>
              Submit
            </Button>
          </Form.Item>

          <div style={{ textAlign: "center" }}>
            Already have email and password?<Button type="link" onClick={(e) => onOpenLogin(e)}>Login</Button>
          </div>
        </Form>
      </Modal>    
  );
};

export default ForgotPasswordForm;
