import { Row, Tag } from "antd"
import { getOppThemeColor } from "../../utils/utils"
import { useTheme } from "../../contexts/ThemeContext"

interface TimeBtnListType {
    durationMap: any,
    activeButton: any, 
    handleTimeBtnClick: any,
    className: string,
    style?: any
}
export const TimeBtnList: React.FC<TimeBtnListType> = ({durationMap, 
                                       activeButton,
                                       handleTimeBtnClick,
                                       className,
                                       style
                                        }) => {
    const {theme} = useTheme()
    return <Row gutter={[8,8]} 
                justify='center'
                align='middle'                                                    
                className={className}
                style={style}>
    {
      Object.entries(durationMap).map(([durationId, obj]: any, index) => 
          (
          <div key={`tag-time-btn-${index}`}>
            <Tag 
              style={{fontWeight: activeButton === index ? 900 : 'normal',
                      cursor: 'pointer',
                      color: getOppThemeColor(theme)
                    }}
              onClick={(e)=>handleTimeBtnClick(index, durationId, obj)}>
              <span>{obj.text}</span>
            </Tag>
          </div>
        ))
    }
    </Row>

}