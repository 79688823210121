import React from 'react';
import { Helmet } from "react-helmet-async";

interface MetaTagsProps {
  title: string;
  description: string;
  imageUrl: string;
  url: string;
  keywords: string;
}

const MetaTags: React.FC<MetaTagsProps> = ({ title, description, imageUrl, url, keywords }) => {
  const slicedDescription = description.slice(0, 155); // Ensure it's within SEO limits
  return (
    <Helmet>
      {/* SEO Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={slicedDescription} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={url} />

      {/* Open Graph (Facebook/LinkedIn) Meta Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={slicedDescription} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="article" />
      <meta property="og:site_name" content="AI NewsHub" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={slicedDescription} />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:site" content="@CeADARIreland" />
    </Helmet>
  );
};

export default MetaTags;
