import { Pie } from '@ant-design/plots';
import { useTheme } from '../../../contexts/ThemeContext';
import { getOppThemeColor } from '../../../utils/utils';

interface DonutChartType {
  data: any,
  handleDonutItemClick: (obj: any) => void,
  x: string,
  y: string,
  content: string
}

const COLORS = [
  "#0050A0",
  "#388E3C",
  "#007850",
  "#1565C0",
  "#BDA436",
  "#FF7043",
  "#5E463F",
  "#311B92",
  "#C62828",
  "#005F9D",
  "#8B0DAD",
  "Turquoise",  
  "#FDD835",
  "#757575",
  "#E53935"
]



const DonutChart: React.FC<DonutChartType> = ({data, handleDonutItemClick, x, y, content}) => {
  const {theme} = useTheme()
  const config: any = {
    appendPadding: 10,
    data,
    angleField: x,
    colorField: y,
    color: COLORS,
    radius: 1,
    innerRadius: 0.6,
    legend: {
      position: 'right',
      itemName: {
        style: {
          fill: getOppThemeColor(theme),
          fontSize: 16,
          fontFamily: `AliPuHui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'`
        }
      }
    },    
    label: {
      type: 'inner',
      offset: '-50%',
      content: (obj: any) => `${obj.percent > 0.03 ? `${(obj.percent * 100).toFixed(0)}%` : ''}`,
      style: {
        textAlign: 'center',
        fontSize: 14,
        fill: getOppThemeColor(theme)
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: 20
        },
        content: content,
      },
    },
    onReady: (plot: any) => {
      plot.on('element:click', (e: any) => {
        handleDonutItemClick(e.data)
      })
    },
  };

  return <Pie {...config}/>;
};

export default DonutChart