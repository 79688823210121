import React from 'react'
import '../../css/WhitePaper.css'

const WhitePaperDesc: React.FC = () => {
    return (
        <div className='whitepaper-text-desc'>
            <h1>Dive into the AI evolution with "AI Roundup 2023 - Trends & Projections for 2024", your comprehensive guide to the past year's AI landscape and a forward look into 2024's potential.</h1>
            <br/>
            <h2>Through AI NewsHub's lens, this white paper explores:</h2>
                <ul>
                    <li><strong >• 2023 Recap:</strong> Key trends, regional nuances, and leading voices in AI.</li>
                    <li><strong>• Quarterly Insights:</strong> In-depth analysis of each quarter's significant AI developments.</li>
                    <li><strong>• Industry Impact:</strong> How various sectors are being transformed by AI.</li>
                    <li><strong>• Future Outlook:</strong> Projections for 2024, focusing on emerging opportunities, particularly in Generative AI.</li>
                </ul>
        </div>
    )
}

export default WhitePaperDesc