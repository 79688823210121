import {
  message
} from "antd";

export const getShareableText = (title:string) => `
Discover "${title}" on AI NewsHub!

Are you into AI? Sign up today at AINewsHub.ie for free to discover the latest in AI/ML and stay ahead of the curve.

`;

export const getShareableArticleUrl = (id:number) => {
  const protocol = window.location.protocol; // e.g., "http:" or "https:"
  const hostname = window.location.hostname; // e.g., "localhost"
  const port = window.location.port; // e.g., "3000"

  // Construct base URL
  const baseUrl = `${protocol}//${hostname}${port ? `:${port}` : ""}`; // Include port only if it's not empty

  // Return the complete article URL
  return `${baseUrl}/content/article/${id}`;
};

export const handleCopyLink = (link:string) => {
  navigator.clipboard.writeText(link).then(() => {
    message.success('Link copied to clipboard!');
  }).catch(() => {
    message.error('Failed to copy the link.');
  });
};