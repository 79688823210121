import React from "react";

import { Layout } from "antd";
import UserProfile from './UserProfile'

const { Content  } = Layout;

const UserProfileCreation: React.FC = () => {
  return (
    <Layout style={{background: "#fff"}}>
      <Content>
        <UserProfile newProfile={true}/>
      </Content>
    </Layout>
  );

};

export default UserProfileCreation;



