import React from 'react';
import { Row, 
         Col, 
         Spin} from 'antd';

import '../../css/Common.css'

import "dayjs/locale/en"; 

import { GridInfo } from '../../types';
import { NewsCard } from './NewsCard';

interface CardSectionType {
    sectionKey?: string,
    newsData: any,
    gridInfo: GridInfo,
    loading?: boolean,
    widthScale: number
}

const CardSection: React.FC<CardSectionType> = ({newsData, 
                                                 gridInfo, 
                                                 loading,
                                                  widthScale}) => {
    return (
      <Spin spinning={loading}>
        <Row gutter={[16,16]} justify="center" align="middle">
          {
            newsData.map((card: any, index: any) => (
          <Col key={index} xs={gridInfo.xs} sm={gridInfo.sm} md={gridInfo.md} lg={gridInfo.lg} xxl={gridInfo.xxl}>
            <NewsCard card={card} widthScale={widthScale}/>
          </Col>
        ))}
        </Row>
      </Spin>
    )
  }

export default CardSection;