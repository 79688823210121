import { GridInfo } from "../../types"

export const gridInfo: GridInfo = {
  xxl: 6,
  lg: 6,
  md: 8,
  sm: 12,
  xs: 24
}

  
export  const MAX_TRENDING_PER_SECTION = 3
export const CARD_PER_PAGE = 36
export const HOME_CARDS = 4