import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Form, Input, Button, message, Layout, Row,Col, Alert } from "antd";
import {
  LockOutlined} from "@ant-design/icons";
import "../css/SignupPage.css";
import logo from '../images/newshub-logo-main.png';
import api from "../api/api"
import {  useNavigate } from "react-router-dom";

const API_URL : string = (process.env.REACT_APP_API_URL as string)

const ResetPasswordConfirmForm: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage()  
  const { token } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const [validToken, setValidToken] = useState(true)
  const navigate = useNavigate()
  
  useEffect(() => {
    const validate_token = async () => {
      try {
        await api.post(`${API_URL}/password_reset/validate_token/`, { 
          token: `${token}`
        })
        setValidToken(true)
      }
      catch (err: any) {
        setValidToken(false)
      }  
    }
    validate_token()
  }, [])
  
  const onSubmit = async (values: any) => {
    try {
        setIsLoading(true)
        await api.post(`${API_URL}/password_reset/confirm/`, { 
          token: `${token}`,
          password: values.confirmPassword
        })
        messageApi.open({
          type: 'success',
          content: "Password reset successfully",
          duration: 1
        }).then(() => {
            messageApi.open({
              type: 'loading',
              content: "Redirecting to home page",
              duration: 1
            }).then(() => {
              navigate('/')
            })
        });


      } catch (err: any) {
        messageApi.open({
          type: 'error',
          content:`Reset password failed: ${err.response.data.password[0]}`,
          duration: 5
        });
        console.log(err)

      } finally{
        setIsLoading(false);
      }
  };

  return (
    <div>
    { validToken ? (
    <Layout>      
        <Row justify="center" align="middle" className="signup-page">
          <Col span={8}>
            <div className="signup-page__form-container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 24,
                }}
              >
                <a href="/">
                <img
                  src={logo}
                  alt="Logo"
                  style={{ height: 100 }}
                />
                </a>
              </div>                 

              <Form name="signup" onFinish={onSubmit}>
                <h1 className="signup-page__title">Reset Password</h1>
                <Form.Item
                  name="password"
                  rules={[{ required: true, message: "Please input your password!" }]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="New Password"
                    style={{ paddingLeft: 10 }}
                    required
                  />
                </Form.Item>
                <Form.Item
                  name="confirmPassword"
                  rules={[
                    { required: true, message: "Please confirm your password!" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("The two passwords that you entered do not match!")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Confirm Password"
                    style={{ paddingLeft: 10 }}
                    required
                  />
                </Form.Item>
                
                {contextHolder}
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    className="signup-page__submit-button"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
    </Layout>
  ) : (
    <div>
         <Alert
          message="Oops. Your token is not valid"
          type="error"
          showIcon
          style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        />
    </div>
  )
}
</div>
  )
};

export default ResetPasswordConfirmForm;
