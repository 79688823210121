import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Row
} from 'antd';
import { useNavigate } from "react-router-dom";
import { useAutheDataContext } from '../../contexts/AuthDataContext';
import '../../css/Common.css';
import "dayjs/locale/en";

import api from '../../api/api';
import { GridInfo } from '../../types';
import { API_URL, getFirstPart, getOppThemeColor, homeSectionMap} from '../../utils/utils';
import CardSection from './CardSection';
import { UserModals } from '../main/UserModals';
import { HOME_CARDS } from './configs';
import { useTheme } from "../../contexts/ThemeContext"

interface Props {
  sectionKey: string,
  gridInfo: GridInfo,
}

const MAX_DISPLAY_CARDS = 4

const HomeCards: React.FC<Props> = ({ sectionKey, 
                                      gridInfo}) => {
  
  const [openLogin, setOpenLogin] = useState(false)
  const [openSignUp, setOpenSignUp] = useState(false)
  const [openForgotPassword, setOpenForgotPassword] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const {theme} = useTheme()
                                            
  const handleReadMores = (e: any, sectionKey: any) =>  {
    if (user === null)  {
      setOpenLogin(true)
    } else {
      if (userProfile?.hasSubscription) {
        navigate(`/content/premium/${sectionKey}`)
      } else {
        navigate(`/content/basic/${sectionKey}`)
      }
    }
  }
                                        
  const navigate = useNavigate()

  const {user, userProfile} = useAutheDataContext()
  const [newsData, setNewsData] = useState([])
  
  const fetchData = async (queryStr: string) => {
    try {
      setLoading(true)
      let res = await api.get(queryStr)
      let displayData = res.data.results
      displayData.map((x: any) => (x.contentSummary = getFirstPart(x.summary)))

      if (displayData.length > MAX_DISPLAY_CARDS) {
        displayData = displayData.slice(0, MAX_DISPLAY_CARDS)
      }

      // if (userProfile) {
      //   displayData = sortByPreference(userProfile, displayData)
      // }
      setNewsData(displayData)  
    } catch (err) {
      console.log('err:', err)
    }
    finally {
      setLoading(false)
    }
  }

  useEffect(() => {   
    let queryStr = `${API_URL}/contents/articles/?page_size=${HOME_CARDS}&${homeSectionMap[sectionKey].param}`;
    fetchData(queryStr)
  }, [sectionKey])

  return (
    <div {...process.env.REACT_APP_TESTING ? { 'data-testid': 'homecard' } : {}}>      
      <Row justify="center" align="middle">
        <Col span={2}/>
        <Col span={20}>
          {
            (newsData.length > 0) && (
              <Divider orientation="left"  style={{ borderColor:  "#b6bab3" }}>
                <h3 className="section-title" style={{color: getOppThemeColor(theme)}}>
                  {homeSectionMap[sectionKey].sectionTitle}
                </h3>
              </Divider>
            )
          }          
          <CardSection sectionKey={sectionKey} 
                       newsData={newsData} 
                       gridInfo={gridInfo} 
                       loading={loading}
                       widthScale={1}/>

          {
            (newsData.length > 0) && (
              <Row style={{paddingTop: "1.5rem"}}>
                <Col flex={1} key="emptyColCardSection"/>
                <Col flex={1} key="readyMoreButton">
                  <Button 
                      type="primary" 
                      size='large' 
                      className='control-button'> 
                      <strong key='readmore' 
                              className="menu-text" 
                              onClick={(e) => handleReadMores(e, sectionKey)}>
                        Discover More
                      </strong>
                  </Button>   
                  {
                    <UserModals openLogin={openLogin}
                                setOpenLogin={setOpenLogin}
                                openSignUp={openSignUp}
                                setOpenSignUp={setOpenSignUp}
                                openForgotPassword={openForgotPassword}
                                setOpenForgotPassword={setOpenForgotPassword}/>
                  }    
                </Col>
            </Row>  
            )
            }          
        
        </Col>
        <Col span={2}/>
      </Row>
    </div>
  );
};

export default HomeCards;
